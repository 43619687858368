import React from "react";
import Aos from "aos";
import { useEffect } from "react";
import "aos/dist/aos.css";
import {
	Carousel,
	CarouselContent,
	CarouselItem,
	CarouselNext,
	CarouselPrevious,
} from "../component/carousel";
const Testimonialsen = () => {
	useEffect(() => {
		Aos.init({ duration: 1000, delay: 200 });
	});

	const data = [
		{
			img: "/assets/testimonial1.jpeg",
			text: "Working with this team was an excellent experience. They managed all the legal aspects related to my construction project, and their attention to detail and problem-solving abilities were outstanding.",
			name: "Khalid Al-Jabari",
			profession: "Architect",
		},
		{
			img: "/assets/testimonial2.jpeg",
			text: "The legal advice I received was invaluable. The attorneys handled my case with great competence and ensured that my rights were protected at all times. I highly recommend their services to anyone needing reliable legal counsel.",
			name: "Tariq Al-Harbi",
			profession: "Doctor",
		},
		{
			img: "/assets/testimonial3.jpeg",
			text: "Their expertise in finance and corporate law was evident from the start. The lawyers provided clear and effective advice on investment and merger matters, which was crucial for the success of my transactions.",
			name: "Omar Al-Mansoori",
			profession: " Financial Consultant",
		},
		{
			img: "/assets/testimonial3.jpeg",
			text: "Their expertise in finance and corporate law was evident from the start. The lawyers provided clear and effective advice on investment and merger matters, which was crucial for the success of my transactions.",
			name: "Omar Al-Mansoori",
			profession: " Financial Consultant",
		},
	];
	return (
		<div className=" overflow-x-hidden w-full bg-[#111b29] overflow-hidden h-fit py-14 pb-20">
			<h1
				data-aos="fade-down"
				className=" font-custom text-center pt-[60px] px-3 text-white font-bold text-5xl">
				Testimonials
			</h1>
			<p
				data-aos="fade-down"
				className="text-center  px-3 pt-2 text-white font-normal text-lg">
				What our clients have to say
			</p>
			<Carousel data-aos="fade-left" className="w-full mb-10  mt-10   flex items-center justify-center ">
				<CarouselContent className='w-full mb-10  ' >

					{
						data.map((data) => {
							return (
								<CarouselItem className="   min-[868px]-1/2 min-[1386px]:basis-1/3" >
									<TestimonialCards text={data.text} img={data.img} name={data.name} profession={data.profession} />
								</CarouselItem>
							)
						})
					}
				</CarouselContent>
				<div>

				<CarouselPrevious />
				</div>
				<div>

				<CarouselNext />
				</div>
			</Carousel>
		</div>
	);
};

export default Testimonialsen;

const TestimonialCards = ({ text, name, profession, img }) => {
	return (
		<div className=" bg-[#111b29] p-8 border-red-50  border  rounded-xl h-fit w-[400px] ">
			<div className=" w-full flex  items-center  justify-between">
				<img src="/assets/qouta.png" alt="" srcset="" />
				<img
					src={img}
					className="  rounded-bl-full h-[120px] w-[150px] "
					alt=""
					srcset=""
				/>
			</div>
			<p className=" text-white font-normal text-lg mt-10">{text}</p>
			<div className=" w-full mt-14 flex flex-col text-left">
				<p className=" text-white font-bold text-lg mt-10">{name}</p>
				<p className=" text-white font-[400] text-lg ">{profession}</p>
			</div>
		</div>
	);
};
