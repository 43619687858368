import React from "react";
import Aos from "aos";
import { useEffect } from "react";

import "aos/dist/aos.css";
const Founder = () => {
	useEffect(() => {
		Aos.init({ duration: 1000, delay: 200 });
	});
	return (
		<div
			className="relative w-full min-h-screen bg-cover bg-center text-white flex items-center justify-center"
			style={{ backgroundImage: `url('assets/Founder.png')` }}>
			<div className="container mx-auto py-16 px-6 text-center max-w-7xl">
				<h1 data-aos="fade-up" className="text-6xl font-bold text-center mb-6">
					رؤية المؤسسين
				</h1>
				<p data-aos="fade-up" className="text-center text-lg mb-16">
					الريادة وتزاوج الجودة لتشكيل مستقبل الخدمات القانونية
				</p>

				<div className="flex flex-col items-center space-y-12">
					<div className="flex flex-col md:flex-row items-center justify-center md:space-x-12">
						<img
							data-aos="fade-right"
							src="assets/lawyer1.jpeg"
							alt="Lawyer 1"
							className="w-80 h-80 rounded-full object-cover object-top mb-4 md:mb-0"
						/>
						<div
							data-aos="fade-left"
							className="text-left max-w-2xl flex flex-col gap-2">
							<h2 className="text-2xl font-bold mb-2 text-[#C8AD44]">محامي</h2>
							<h3 className="text-3xl font-bold mb-2">
								محمد بن عبدالرحمن الرشيد
							</h3>
							<p
								className="leading-7"
								style={{ direction: "ltr", unicodeBidi: "plaintext" }}>
								حاصل على البكالوريوس في القانون من جامعة الملك سعود، وحاصل على
								درجة الماجستير في القانون من جامعة ساوثرن ميثويست دالاس بتكساس
								بالولايات المتحدة الأمريكية، وباحث دكتوراه، وحاصل على دبلوم
								الدراسات المصرفية والمالية من معهد البحرين للدراسات المصرفية
								والمالية. عمل في عدد من الجهات كإمارة المنطقة الشرقية وشركات
								عائلية، وقد تولى التحكيم في عدد من القضايا التجارية والمدنية،
								وهو محامي وشريك مؤسس في شركة الرشيد والمسيند للمحاماة
								والإستشارات القانونية منذ عام 2013م. انضم لعدد من العضويات
								كالهيئة الاستشارية للمحامين بوزارة العدل سابقا، وعضو لجنة
								المحامين والمستشارين القانونيين بغرفة الشرقية سابقا، وعضو لجنة
								المجتمع القانوني بالمنطقة الشرقية سابقا، وعضو لجنة الانضباط
								بالاتحاد السعودي لكرة القدم سابقًا، وعضو أساسي بالهيئة السعودية
								للمحامين، وعضو مجلس إدارة النهضة لمشروع الخصخصة. وقد التحق بعدد
								من الدورات المتخصصة في المجال القانوني من أهمها ما نظمه معهد
								الإدارة العامة.
							</p>
						</div>
					</div>

					<div className="flex flex-col md:flex-row-reverse items-center justify-center md:space-x-12 space-x-reverse">
						<img
							data-aos="fade-left"
							src="assets/lawyer2.png"
							alt="Lawyer 2"
							className="w-80 h-80 rounded-full object-cover object-top mb-4 md:mb-0"
						/>
						<div
							data-aos="fade-right"
							className="text-left max-w-2xl flex flex-col gap-2">
							<h2 className="text-2xl font-bold mb-2 text-[#C8AD44]">محامي</h2>
							<h3 className="text-3xl font-bold mb-2">
								الدكتور بندر بن عبدالرحمن المسيند
							</h3>
							<p
								className="leading-7"
								style={{ direction: "ltr", unicodeBidi: "plaintext" }}>
								حاصل على البكالوريوس في القانون من جامعة الملك فيصل، وحاصل على
								درجة الماجستير في القانون من جامعة الملك عبدالعزيز، وحاصل على
								درجة الدكتوراه في القانون من جامعة البحرين. عمل كباحث قضايا في
								إدارة الحقوق الخاصة بإمارة المنطقة الشرقية، ومن ثم مستشارًا
								قانونيًا في إدارة الشؤون القانونية بالشركة السعودية للكهرباء مثل
								فيها الشركة في عدد من الجهات كما مثل وزارة المياه والكهرباء في
								عدد من القضايا، وقد تولى التحكيم في عدد من القضايا التجارية
								والمدنية، وهو محامي وشريك مؤسس في شركة الرشيد والمسيند للمحاماة
								والإستشارات القانونية منذ عام 2013م. وعضو في لجنة المحامين
								بالمنطقة الشرقية بالهيئة السعودية للمحامين، وعضو لجنة الأوقاف
								بغرفة الشرقية للدورة (19). وقد التحق بعدد من الدورات المتخصصة في
								المجال القانوني من أهمها ما نظمته جامعة نايف العربية للعوم
								الأمنية، وكذلك معهد الإدارة العامة، وأيضًا المنظمة العربية
								للتنمية الإدارية، وهيئة حقوق الإنسان بالمملكة العربية السعودية
								بالتعاون مع مكتب الأمم المتحدة المعني بالمخدرات والجريمة UNODC.
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Founder;
