import React from "react";
import Aos from "aos";
import { useEffect } from "react";
import "aos/dist/aos.css";

const Vision2en = () => {
	useEffect(() => {
		Aos.init({ duration: 1000, delay: 200 });
	});
	return (
		<div
			className="relative w-full h-[400px] sm:h-[600px] bg-cover bg-center flex justify-center items-center py-24 px-8 md:p-28 lg:p-36"
			style={{ backgroundImage: `url('/assets/Vision section2.png')` }}>
			<div
				data-aos="slide-right"
				className="text-white text-left p-4 max-w-7xl gap-8 flex flex-col">
				<h1 className="text-[32px] font-custom sm:text-xl md:text-[30px] lg:text-[50px] font-bold leading-relaxed">
					Our vision
				</h1>
				<p
					className="text-[15px] sm:text-xl md:text-[17px] lg:text-[25px] leading-relaxed "
					style={{
						lineHeight: "1.5",
						direction: "ltr",
						unicodeBidi: "plaintext",
					}}>
					We always seek to establish the legal profession in the Saudi society
					and to educate individuals and entities about the importance of
					safeguarding their rights guaranteed by law and law by meeting the
					legal needs that satisfy the wishes of the beneficiaries and enabling
					them to start their work in such a way as to provide them with the
					appropriate legal protection.
				</p>
			</div>
		</div>
	);
};

export default Vision2en;
