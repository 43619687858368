import React from 'react';
import ServicePageHeroen from "../component-en/servicePageHero-en";
import Awardsen from "../component-en/Awards-en";
import Consulten from "../component-en/Consult-en";
import PracticeAreasen from "../component-en/PracticeArea-en";
import Testimonialsen from "../component-en/testimonials-en";
const ServicesPageen = () => {
	return (
		<>
			<ServicePageHeroen />
			<PracticeAreasen />
			<Awardsen />
			<Testimonialsen/>
			<Consulten />
		</>
	);
};

export default ServicesPageen;
