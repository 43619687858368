import React from "react";
import Aos from "aos";
import { useEffect } from "react";

import "aos/dist/aos.css";
const AboutpageHeroen = () => {
	useEffect(() => {
		Aos.init({ duration: 1000, delay: 200 });
	});
	return (
		<div
			style={{
				backgroundImage: `url("../assets/hero2.jpeg")`,
				backgroundPosition: "center",
				backgroundSize: "cover",
			}}
			className=" relative w-full h-[100vh]">
			<div className="absolute inset-0 bg-black bg-opacity-70 flex flex-col items-start justify-center">
				<div
					data-aos="fade-right"
					className="text-center text-white flex
                 items-start pl-[200px]
                 max-[936px]:pl-[80px] max-[714px]:pl-[20px] justify-center flex-col">
					<p className="text-xl    mb-1">Who we are</p>
					<h1 className="text-6xl font-custom max-[714px]:text-4xl font-bold  mb-8 ">
						Lawyers and Advisers
					</h1>
					<p
						className="text-xl text-left  font-[400] max-[714px]:pr-4 max-[714px]:text-lg max-[714px]:font-[200]  leading-8 mb-8"
						style={{ direction: "ltr", unicodeBidi: "plaintext" }}>
						Al Rashid & Al Musind Law Firm: We are a team of experienced and
						highly qualified lawyers committed <br /> to providing the best
						legal services to our clients. We offer a wide range of legal
						services, <br />
						including: Legal consultations in various fields Representation of
						clients in commercial <br /> and civil cases Arbitration and legal
						training
					</p>

					<button className="font-bold px-16 py-3 text-black text-lg bg-white hover:bg-black hover:text-white  transition-all duration-300">
						<a href="/en/consultation">Request a Consultation</a>
					</button>
				</div>
			</div>
		</div>
	);
};

export default AboutpageHeroen;
