import React from "react";
import Aos from "aos";
import { useEffect } from "react";

import "aos/dist/aos.css";
const Homelanding = () => {
	useEffect(() => {
		Aos.init({ duration: 1000, delay: 200, offset: 1 });
	});
	return (
		<div className=" overflow-x-hidden w-full h-[100vh] max-[1300px]:h-fit   bg-[#F2F3FA]  flex  items-end  justify-end  gap-20 max-[1300px]:flex-col max-[1300px]:items-center    max-[1300px]:gap-5">
			<div
				data-aos="fade-right"
				className=" mt-10 hidden   max-[1300px]:flex h-fit flex-col max-[1300px]:items-center justify-center  px-3 gap-5">
				<h1
					data-aos="fade-right"
					className=" font-bold text-[80px] max-[742px]:leading-[75px]  max-[742px]:text-5xl max-[742px]:mt-20 max-[742px]:px-3 max-[742px]:text-center  leading-[95px]  text-[#191C20]  ">
					{" "}
					<br />
					شركاؤك القانونيون <br />         
					الموثوق بهم من <br /> أجل مستقبل آمن
				</h1>
				<h1
					className=" font-normal text-lg text-center text-[#191C20] sm:text-left"
					style={{ direction: "ltr", unicodeBidi: "plaintext" }}>
					يقع مقرنا الرئيسي في المنطقة الشرقية <br />
					ونفخر بخدمة العملاء في جميع مناطق المملكة .
				</h1>
				<button className="font-bold px-12 py-3 text-white text-lg bg-[#001C38] hover:bg-opacity-90  transition-all duration-200">
					<a href="/consultation">شاهد جميع الخدمات</a>
				</button>
			</div>
			<div
				data-aos="fade-left"
				className=" pt-20 relative px-2 pr-[200px] max-[1536px]:pr-[50px] max-[1300px]:pr-2 ">
				<div
					data-aos-offset="-100"
					data-aos="fade-left"
					className=" max-[1300px]:hidden absolute left-[-500px] flex h-fit flex-col max-[1242px]:items-center justify-center items-start px-3 gap-5">
					<h1 className=" font-bold text-[80px] leading-[95px]  text-[#191C20] max-[742px]:text-3xl  ">
						{" "}
						<br />
						شركاؤك القانونيون <br />
						الموثوق بهم من <br /> أجل مستقبل آمن
					</h1>
					<h1
						className=" font-normal text-lg text-[#191C20] sm:text-left"
						style={{ direction: "ltr", unicodeBidi: "plaintext" }}>
						{" "}
						يقع مقرنا الرئيسي في المنطقة الشرقية، <br />
						ونفخر بخدمة العملاء في جميع مناطق المملكة .
					</h1>
					<button className="font-bold px-20 py-3 text-white text-lg bg-[#001C38] hover:bg-white hover:text-black border-2 border-black transition-all duration-300">
						<a href="/consultation">طلب استشارة</a>
					</button>
				</div>
				<img src="../assets/hero1.png" alt="" srcset="" />
			</div>
		</div>
	);
};

export default Homelanding;
