import React, { useState, useEffect } from "react";
import { MenuIcon } from "lucide-react";
import { useLocation } from "react-router-dom";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import Aos from "aos";
import "aos/dist/aos.css";

const Navbar = () => {
	const [menubar, setMenubar] = useState(true);
	const [isScrolled, setIsScrolled] = useState(false);
	const [textcolor, setTextcolor] = useState("text-black");
	const [isOpen, setIsOpen] = React.useState(false);
	const toggleDrawer = () => {
		setIsOpen((prevState) => !prevState);
	};

	let location = useLocation();

	function toggleMenu() {
		setMenubar(!menubar);
	}

	useEffect(() => {
		Aos.init({ duration: 1000, delay: 200 });
		if (location.pathname === "/awards&honors") {
			setTextcolor("text-[#73777F]");
		}
		if (location.pathname === "/") {
			setTextcolor("text-[#73777F]");
		}
		if (location.pathname === "/about") {
			setTextcolor("text-white");
		}
		if (location.pathname === "/team") {
			setTextcolor("text-[#73777F]");
		}
		if (location.pathname === "/services") {
			setTextcolor("text-[#73777F]");
		}
		if (location.pathname === "/consultation") {
			setTextcolor("text-[#73777F]");
		}

		const handleScroll = () => {
			setIsScrolled(window.scrollY > 50); // Threshold for scroll event
		};

		window.addEventListener("scroll", handleScroll);
		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);

	return (
		<div
			data-aos="fade-in"
			className={` z-50 px-20 w-full h-fit fixed top-0 flex max-[584px]:px-3 items-center justify-between transition-all duration-300 ${
				isScrolled ? "bg-white text-black" : "bg-transparent text-white"
			}`}>
			<div className="flex items-center justify-center p-1">
				<img src="../assets/logo.png" className="w-[180px]" alt="Logo" />
			</div>
			<div className="text-md font-[700] p-3 flex items-center justify-center gap-12 mr-10 max-[1174px]:hidden">
				<a href="/">
					<div
						className={`hover:cursor-pointer ${
							isScrolled && location.pathname !== "/"
								? `text-[#73777F]`
								: `${
										location.pathname === "/"
											? "text-[#001C38]"
											: `${textcolor}`
								  }`
						}`}>
						الرئيسية
					</div>
				</a>
				<a href="about">
					<div
						className={`hover:cursor-pointer ${
							isScrolled && location.pathname !== "/about"
								? `text-[#73777F]`
								: `${
										location.pathname === "/about"
											? "text-[#001C38]"
											: `${textcolor}`
								  }`
						}`}>
						من نحن
					</div>
				</a>
				<a href="team">
					<div
						className={`hover:cursor-pointer ${
							isScrolled && location.pathname !== "/team"
								? `text-[#73777F]`
								: `${
										location.pathname === "/team"
											? "text-[#001C38]"
											: `${textcolor}`
								  }`
						}`}>
						فريق
					</div>
				</a>
				<a href="/services">
					<div
						className={`hover:cursor-pointer ${
							isScrolled && location.pathname !== "/services"
								? `text-[#73777F]`
								: `${
										location.pathname === "/services"
											? "text-[#001C38]"
											: `${textcolor}`
								  }`
						}`}>
						خدماتنا
					</div>
				</a>

				<a href="/awards&honors">
					<div
						className={`hover:cursor-pointer ${
							isScrolled && location.pathname !== "/awards&honors"
								? `text-[#73777F]`
								: `${
										location.pathname === "/awards&honors"
											? "text-[#001C38]"
											: `${textcolor}`
								  }`
						}`}>
						الجوائز والأوسمة
					</div>
				</a>

				<div className=" flex items-center justify-center">
					<button className="font-bold px-8 py-2 bg-[#3A608F] text-white hover:bg-opacity-90 transition-all duration-200">
						<a href="/consultation">اتصل بنا</a>
					</button>
					<a href="/en">
						<img
							className=" hover:cursor-pointer"
							src="/assets/language1.png"
							alt=""
							srcset=""
						/>
					</a>
				</div>
			</div>

			{/* Mobile Menu */}
			<div className=" hidden max-[1174px]:flex relative">
				<MenuIcon
					onClick={toggleDrawer}
					className={` ${isScrolled ? "text-black" : textcolor}`}
				/>

				<Drawer
					open={isOpen}
					onClose={toggleDrawer}
					direction="left"
					className=" flex flex-col-reverse w-full">
					<div
						className={`w-full h-full flex bg-white p-3 px-8 flex-col justify-center items-start gap-10 absolute top-8 text-nowrap text-md  right-2 text-[#73777F] font-bold text-2xl    ${
							menubar ? "" : "hidden"
						}`}>
						<a href="/awards&honors">
							<p
								className={` hover:cursor-pointer   ${
									location.pathname === "/awards&honors" && "text-[#001C38]"
								} `}>
								الرئيسية
							</p>
						</a>
						<a href="/services">
							<p
								className={` hover:cursor-pointer ${
									location.pathname === "/services" && "text-[#001C38]"
								} `}>
								من نحن
							</p>
						</a>
						<a href="/team">
							<p
								className={` hover:cursor-pointer ${
									location.pathname === "/team" && "text-[#001C38]"
								} `}>
								فريق
							</p>
						</a>
						<a href="/about">
							<p
								className={` hover:cursor-pointer ${
									location.pathname === "/about" && "text-[#001C38]"
								} `}>
								خدماتنا
							</p>
						</a>
						<a href="/">
							<p
								className={` hover:cursor-pointer ${
									location.pathname === "/" && "text-[#001C38]"
								} `}>
								الجوائز و الأوسمة
							</p>
						</a>
						<a href="/consultation">
							<p
								className={` hover:cursor-pointer ${
									location.pathname === "/consultation" && "text-[#001C38]"
								} `}>
								اتصل بنا
							</p>
						</a>

						<a href="/en">
							<img
								className=" hover:cursor-pointer mt-32"
								src="/assets/language1.png"
								alt=""
								srcset=""
							/>
						</a>
					</div>
				</Drawer>
			</div>
		</div>
	);
};

export default Navbar;
