import React from "react";
import Aos from "aos";
import { useEffect } from "react";

import "aos/dist/aos.css";
const Founderen = () => {
	useEffect(() => {
		Aos.init({ duration: 1000, delay: 200 });
	});
	return (
		<div
			className="relative w-full min-h-screen bg-cover bg-center text-white flex items-center justify-center"
			style={{ backgroundImage: `url('/assets/Founder.png')` }}>
			<div className="container mx-auto py-16 px-6 text-center max-w-7xl">
				<h1
					data-aos="fade-up"
					className="text-6xl font-custom font-bold text-center mb-6">
					Founders' Vision
				</h1>
				<p data-aos="fade-up" className="text-center text-lg mb-16">
					Leading with Integrity and Expertise to Shape the Future of Legal
					Services
				</p>

				<div className="flex flex-col items-center space-y-12">
					<div className="flex flex-col md:flex-row items-center justify-center md:space-x-12">
						<img
							data-aos="fade-right"
							src="/assets/lawyer1.jpeg"
							alt="Lawyer 1"
							className="w-80 h-80 rounded-full object-cover object-top mb-4 md:mb-0"
						/>
						<div
							data-aos="fade-left"
							className="text-left max-w-2xl flex flex-col gap-2">
							<h2 className="text-2xl font-bold mb-2 text-[#C8AD44]">
								Attorney
							</h2>
							<h3 className="text-3xl font-bold mb-2">
								Mr. Mohammed bin Abdulrahman Al-Rasheed
							</h3>
							<p
								className="leading-7"
								style={{ direction: "ltr", unicodeBidi: "plaintext" }}>
								Lawyer Mohammed bin Abdulrahman Al-Rasheed is a prominent figure
								in the legal field, known for his robust academic foundation and
								extensive professional experience in law, he is career reflects
								a dedication to advancing the legal profession in Saudi Arabia
								through both practice and active participation in key legal
								initiatives.
								<br />
								<br /> He holds a Bachelor's degree in Law from King Saud
								University and a Master's degree in Law from Southern Methodist
								University in Dallas, Texas, U.S.A. he is also a PhD researcher,
								and holds a diploma in Banking and Financial Studies from the
								Bahrain Institute of Banking and Finance, he has worked at the
								Eastern Province Emirate and family-owned companies. He has
								served as an arbitrator in several commercial and civil cases.
								<br />
								<br />
								He is a founding partner and attorney at AL- Rasheed &
								Al-Musained Law Firm since 2013. He has been a member of various
								advisory bodies, including the Advisory Bodies for Lawyers at
								the Ministry of Justice (formerly), a former member of the
								Lawyers and Consultants Committee of the Eastern Chamber, a
								former member of the Legal Community Committee in the Eastern
								Region, and a former member of the Disciplinary Committee of the
								Saudi Arabian Football Federation, he is also a core member of
								the Saudi Bar Association and a member of the Board of Directors
								of the Renaissance Privatization Project, and he has
								participated in a number of specialized legal courses, including
								those organized by the Institute of Public Administration, and
								continues to provide legal consultations in various fields.
								<br />
								<br />
								Mohammed bin Abdulrahman Al-Rasheed exemplifies the qualities of
								a dedicated and accomplished lawyer. His contributions to the
								legal profession, combined with his academic and practical
								achievements, have established him as a distinguished leader in
								the field.
							</p>
						</div>
					</div>

					<div className="flex flex-col md:flex-row-reverse items-center justify-center md:space-x-12 space-x-reverse">
						<img
							data-aos="fade-left"
							src="/assets/lawyer2.png"
							alt="Lawyer 2"
							className="w-80 h-80 rounded-full object-cover object-top mb-4 md:mb-0"
						/>
						<div
							data-aos="fade-right"
							className="text-left max-w-2xl flex flex-col gap-2">
							<h2 className="text-2xl font-bold mb-2 text-[#C8AD44]">
								Attorney
							</h2>
							<h3 className="text-3xl font-bold mb-2">
								Dr. Bandar bin Abdul Rahman Almusained
							</h3>
							<p
								className="leading-7"
								style={{ direction: "ltr", unicodeBidi: "plaintext" }}>
								Dr. Bandar bin Abdulrahman Al-Musained is one of the prominent
								names in the legal field in Saudi Arabia, combining advanced
								academic qualifications with distinguished practical experience,
								making him a key reference in various areas of law.
								<br /> <br />
								He holds a Bachelor's degree in Law from King Faisal University,
								a Master's degree in Law from King Abdulaziz University, and a
								PhD in Law from faculty of law of University of Bahrain, he
								worked as a Researcher in the Private Rights Department of the
								Eastern Province Emirate, then as a lawyer and Legal Advisor in
								the legal affairs department of the Saudi Electricity Company,
								he represented the company in several cases, and also
								represented the Ministry of Water and Electricity in a number of
								cases, he has served as an arbitrator in several commercial and
								civil cases.
								<br />
								<br />
								He is a founding partner and attorney at AL- Rasheed &
								Al-Musained Law Firm and legal consultations since 2013. he is a
								member of the Lawyers Committee of the Saudi Bar Association in
								the Eastern Region, and a member of the endowment committee of
								the Eastern Chamber for the 19th term , and he has participated
								in several specialized legal courses, including those organized
								by Naif Arab University for Security Sciences, the Institute of
								Public Administration, the Arab Organization for Administrative
								Development, and the Saudi Human Rights Commission in
								collaboration with the United Nations Office on Drugs and Crime
								(UNODC). and continues to provide legal consultations in various
								fields.
								<br />
								<br />
								Dr. Bandar’s career represents a model of a successful lawyer
								who has seamlessly combined academic and practical expertise,
								establishing himself as a prominent and influential figure in
								the legal field in Saudi Arabia.
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Founderen;
