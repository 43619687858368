import React from "react";
import TeamPageHeroen from "../component-en/teamPageHero-en";
import Awardsen from "../component-en/Awards-en";
import Consulten from "../component-en/Consult-en";
import Founderen from "../component-en/Founder-en";
import Testimonialsen from "../component-en/testimonials-en";
const TeamPageen = () => {
	return (
		<>
			<TeamPageHeroen />
			<Founderen />
			<Awardsen />
			<Testimonialsen/>
			<Consulten />
		</>
	);
};

export default TeamPageen;
