import React from "react";
import Aos from "aos";
import { useEffect } from "react";
import "aos/dist/aos.css";

const AboutpageHero = () => {
	useEffect(() => {
		Aos.init({ duration: 1000, delay: 200 });
	}, []);

	return (
		<div
			style={{
				backgroundImage: `url("../assets/hero2.jpeg")`,
				backgroundPosition: "center",
				backgroundSize: "cover",
			}}
			className="relative w-full h-[100vh]">
			<div
				className="absolute inset-0 flex flex-col items-start justify-center"
				style={{ backgroundColor: "#001C3894" }}>
				<div
					data-aos="fade-right"
					className="text-center text-white flex items-start pl-[200px]
                 max-[936px]:pl-[80px] max-[714px]:pl-[20px] justify-center flex-col">
					<p className="text-xl mb-1">من نحن</p>
					<h1 className="text-6xl max-[714px]:text-4xl font-bold mb-8">
						محامون ومستشارون
					</h1>
					<p
						className="text-xl text-left font-[400] max-[714px]:pr-4 max-[714px]:text-[14px] max-[714px]:font-[200] leading-8 mb-8"
						style={{ direction: "ltr", unicodeBidi: "plaintext" }}>
						نحن شركات تعمل في مجال تقديم المعلومات والخدمات القانونية بشكل{" "}
						<br />
						احترافي حيث يضم فريقنا نخبة من المحامين والمستشارين القانونيين ذوي{" "}
						<br />
						الكفاءة العلمية والعملية المتميزة. مقرنا الرئيسي بالمنطقة الشرقية{" "}
						<br />
						وخدماتنا تغطي كافة المناطق الجغرافية للمملكة العربية السعودية.
					</p>

					<button className="font-bold px-16 py-3 text-black text-lg bg-white hover:bg-black hover:text-white transition-all duration-300">
						<a href="/consultation">طلب استشارة</a>
					</button>
				</div>
			</div>
		</div>
	);
};

export default AboutpageHero;
