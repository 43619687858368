import React from "react";
import Aos from "aos";
import { useEffect } from "react";
import "aos/dist/aos.css";

const Teamen = () => {
	useEffect(() => {
		Aos.init({ duration: 1000, delay: 200 });
	});
	return (
		<div className="text-left bg-white">
			<div className="relative h-[400px] sm:h-[800px]">
				<img
					src="/assets/Team.jpeg"
					alt="Team Image"
					className="w-full h-full object-cover"
				/>

				<div className="absolute inset-0 bg-black opacity-50"></div>

				<div className="absolute top-1/3 left-10 text-white z-10">
					<h2
						data-aos="slide-left"
						className="lg:text-9xl text-7xl font-bold ml-0 md:ml-20">
						فريقنا
						<br /> المحترف
					</h2>
				</div>
			</div>

			<div className="grid grid-cols-1 md:grid-cols-2 gap-8 p-10 lg:h-[400px]">
				<div className="">
					<p
						data-aos="fade-up"
						className="font-bold text-gray-700 text-2xl "
						style={{ direction: "ltr", unicodeBidi: "plaintext" }}>
						يضم فريقنا المحترف خبراء متخصصين في القانون والاستشارات القانونية.
						يتمتع كل عضو بثروة من المعرفة والخبرة، مما يضمن أننا نقدم خدمات
						قانونية رفيعة المستوى عبر مجموعة واسعة من المجالات.
					</p>
				</div>

				<div>
					<p
						data-aos="fade-up"
						className=" text-gray-700 text-2xl"
						style={{ direction: "ltr", unicodeBidi: "plaintext" }}>
						نحن ملتزمون بتحقيق أقصى استفادة لكل عميل مع الالتزام بأعلى معايير
						الأخلاق والمبادئ القانونية. يرتكز منهجنا على الالتزام بالنزاهة
						والشفافية والتميز، مما يضمن التعامل مع كل مسألة قانونية بأقصى قدر من
						العناية والاحترام للقيم الأساسية لمهنة المحاماة .
					</p>
				</div>
			</div>
		</div>
	);
};

export default Teamen;
