import React from "react";
import Aos from "aos";
import { useEffect } from "react";

import "aos/dist/aos.css";
const Services = () => {
	useEffect(() => {
		Aos.init({ duration: 1000, delay: 500 });
	});
	return (
		<div className="relative overflow-x-hidden z-0 h-full flex items-center justify-center overflow-hidden">
			<div
				className="absolute z-0 inset-0 bg-cover bg-center"
				style={{
					backgroundImage: `url(${"assets/Services.png"})`,
					opacity: 0.1,
					backgroundPosition: "center",
					backgroundSize: "50%",
				}}></div>

			<div className=" z-0 relative max-w-7xl mx-auto mt-[120px] px-12 py-8">
				<div data-aos="fade-right" className="text-left mb-12">
					<h1 className="text-5xl font-custom font-bold text-black mb-4">
						Our Services
					</h1>

					<p
						className="text-lg font-semibold text-gray-600"
						style={{ direction: "ltr", unicodeBidi: "plaintext" }}>
						Expert legal services across all sectors, protecting your interests
						with precision and professionalism.
					</p>
				</div>
				<div
					data-aos="fade-left"
					className="grid grid-cols-1 md:grid-cols-3 gap-8 text-left">
					<div className="bg-transparent p-6">
						<img
							src="assets/Vector.png"
							alt="Arbitration Icon"
							className="mb-4 "
						/>
						<h3 className="text-2xl font-semibold text-black mb-2">
							Arbitration
						</h3>

						<p
							className="text-gray-600 text-lg font-normal"
							style={{ direction: "ltr", unicodeBidi: "plaintext" }}>
							Arbitration quickly resolves local and international disputes with
							straightforward procedures. Our expert team is skilled in acting
							as arbitrators or representing clients before arbitral tribunals.
						</p>
					</div>

					<div className="bg-transparent p-6">
						<img
							src="assets/Vector-1.png"
							alt="Insurance Icon"
							className="mb-4 "
						/>
						<h3 className="text-2xl font-semibold text-black mb-2">
							Insurance
						</h3>
						<p
							className="text-gray-600 text-lg"
							style={{ direction: "ltr", unicodeBidi: "plaintext" }}>
							Insurance issues are widespread issues of concern to individuals
							and entities. Therefore, our team has given special attention to
							providing various legal services and distinct in relation to
							insurance issues.
						</p>
					</div>

					<div className="bg-transparent p-6">
						<img
							src="assets/Vector-2.png"
							alt="Banking Icon"
							className="mb-4 "
						/>
						<h3 className="text-2xl font-semibold text-black mb-2">
							Banking Operations
						</h3>
						<p
							className="text-gray-600 text-lg"
							style={{ direction: "ltr", unicodeBidi: "plaintext" }}>
							Our team offers legal services for banking activities, including
							project financing, Islamic finance, loan restructuring, and
							acquisitions. We also assist with bond issuance and financial
							transactions.
						</p>
					</div>
				</div>
				<div data-aos="fade-up" className="flex justify-center mt-12">
					<button className="font-bold px-12 py-3 text-white text-lg bg-[#001C38] hover:bg-white hover:text-black border-2 border-black transition-all duration-300">
						<a href="/en/services">See all services</a>
					</button>
				</div>
			</div>
		</div>
	);
};

export default Services;
