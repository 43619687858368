import React from "react";
import Aos from "aos";
import { useEffect } from "react";

import "aos/dist/aos.css";

const WhyChooseUsen = () => {
	useEffect(() => {
		Aos.init({ duration: 1000, delay: 200 });
	});
	return (
		<div className="bg-white overflow-x-hidden min-h-screen p-8 flex justify-center items-center">
			<div className="max-w-7xl mx-auto grid grid-cols-1 lg:grid-cols-2 gap-12">
				<div data-aos="fade-right" className="flex justify-center items-center">
					<img
						src="assets/office.jpeg"
						alt="Office"
						className="w-full lg:w-[600px] h-auto rounded-lg shadow-lg object-cover md:h-[870px] sm:h-auto"
					/>
				</div>

				<div data-aos="fade-left" className="space-y-8 mt-12 lg:mt-24">
					<h2 className="text-4xl font-custom md:text-6xl font-bold text-gray-900 text-left">
						Why Choose Us?
					</h2>

					<div className="grid grid-cols-1 lg:grid-cols-2 gap-8 gap-y-12">
						<div className="space-y-2">
							<span className="text-xl ">01</span>
							<h3 className="text-xl font-bold text-gray-900">
								Trusted Local Expertise
							</h3>
							<p className="text-lg text-gray-700">
								Our team consists of seasoned Saudi attorneys who have a deep
								understanding of the local legal landscape, ensuring that you
								receive expert guidance for all your legal needs.
							</p>
						</div>

						<div className="space-y-2">
							<span className="text-xl">02</span>
							<h3 className="text-xl font-bold text-gray-900">
								Tailored Legal Solutions
							</h3>
							<p className="text-lg text-gray-700">
								We offer innovative, client-focused legal strategies that cater
								to the unique complexities of each case, utilizing advanced
								legal technologies to keep you ahead.
							</p>
						</div>

						<div className="space-y-2">
							<span className="text-xl ">03</span>
							<h3 className="text-xl font-bold text-gray-900">
								Proven Success in Saudi Law
							</h3>
							<p className="text-lg text-gray-700">
								With a track record of successful legal outcomes and satisfied
								clients, our firm is committed to delivering the best results,
								while upholding the laws and traditions of Saudi Arabia.
							</p>
						</div>

						<div className="space-y-2">
							<span className="text-xl ">04</span>
							<h3 className="text-xl font-bold text-gray-900">
								Dedication to Integrity and Excellence
							</h3>
							<p className="text-lg text-gray-700">
								Our commitment to professionalism and excellence ensures that we
								provide top-tier legal services with the highest standards of
								integrity.
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default WhyChooseUsen;
