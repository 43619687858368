import React from "react";
import AboutpageHero from "../component/aboutpageHero";
import Team from "../component/Team";
import Awards from "../component/Awards";
import Consult from "../component/Consult";
import Vision2 from "../component/Vision2";
import Testimonials from "../component/testimonials";
const AboutPage = () => {
	return (
		<>
			<AboutpageHero />
			<Vision2 />
			<Team />
			<Awards />
			<Testimonials/>
			<Consult />
		</>
	);
};

export default AboutPage;
