import React from "react";
import Aos from "aos";
import { useEffect } from "react";
import "aos/dist/aos.css";

const Teamen = () => {
	useEffect(() => {
		Aos.init({ duration: 1000, delay: 200 });
	});
	return (
		<div className="text-left bg-white">
			<div className="relative h-[400px] sm:h-[800px]">
				<img
					src="/assets/Team.jpeg"
					alt="Team Image"
					className="w-full h-full object-cover"
				/>

				<div className="absolute inset-0 bg-black opacity-50"></div>

				<div className="absolute top-1/3 left-10 text-white z-10">
					<h2
						data-aos="slide-left"
						className="font-custom lg:text-9xl text-6xl font-bold ml-0 md:ml-20">
						Our <br /> Professional
						<br /> Team
					</h2>
				</div>
			</div>

			<div className="grid grid-cols-1 md:grid-cols-2 gap-8 p-10 lg:h-[400px]">
				<div className="">
					<p
						data-aos="fade-up"
						className="font-bold text-gray-700 text-2xl "
						style={{ direction: "ltr", unicodeBidi: "plaintext" }}>
						Our professional team comprises experts specializing in law and
						legal advice. Each member brings a wealth of knowledge and
						experience, ensuring that we provide top-tier legal services across
						a wide range of areas.
					</p>
				</div>

				<div>
					<p
						data-aos="fade-up"
						className=" text-gray-700 text-2xl"
						style={{ direction: "ltr", unicodeBidi: "plaintext" }}>
						We are dedicated to maximizing the benefit for each client while
						adhering to the highest standards of legal ethics and principles.
						Our approach is grounded in a commitment to integrity, transparency,
						and excellence, ensuring that every legal matter is handled with the
						utmost care and respect for the legal profession's core values.
					</p>
				</div>
			</div>
		</div>
	);
};

export default Teamen;
