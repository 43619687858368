import React from "react";
import Aos from "aos";
import { useEffect } from "react";

import "aos/dist/aos.css";
const AwardsPageHero = () => {
	useEffect(() => {
		Aos.init({ duration: 1000, delay: 200 });
	});
	const awards = [
		{
			title: "بار جمعية",
			description:
				"تكريم نقابة المحامين لبرنامج حوكمة التصفيات المدنية والتجارية د",
			imgSrc: "/assets/man1.jpeg",
		},
		{
			title: "غرفة الرعاية الذهبية",
			description:
				"تكريم من الغرفة للرعاية الذهبية لملتقى حوكمة الشركات العائلية",
			imgSrc: "/assets/man2.jpeg",
		},
		{
			title: "أفضل مؤسسة",
			description:
				"جائزة د. غازي القصيبي عن أفضل مؤسسة في دورتها السادسة في الاستشارات القانونية",
			imgSrc: "/assets/man3.jpeg",
		},
	];

	return (
		<div className="relative  z-0 h-fit flex items-center flex-col  justify-center ">
			<div
				className="absolute z-0 inset-0 h-full bg-cover bg-center"
				style={{
					backgroundImage: `url("../assets/bg-img.png")`,
					opacity: 0.1,
					backgroundPosition: "center",
					backgroundSize: "50%",
				}}></div>

			<div
				data-aos="fade-down"
				className=" z-0 relative max-w-7xl mx-auto mt-[120px] px-12 py-8">
				<div className="text-center mb-8">
					<h1 className="text-6xl font-bold text-[#001C38] mb-4">
						الجوائز والأوسمة
					</h1>

					<p className="text-2xl font-semibold mt-8 text-gray-600">
						الاحتفال بجوائزنا المرموقة وتكريم الصناعة للإنجازات المتميزة
					</p>
				</div>

				<div className="flex justify-center mt-8">
					<button className="font-bold px-12 py-3 text-white text-lg bg-[#001C38] hover:bg-white hover:text-black border-2 border-black transition-all duration-300">
						<a href="/consultation">شاهد جميع الخدمات</a>
					</button>
				</div>
			</div>
			<div
				data-aos="fade-up"
				className=" z-50 w-full flex h-fit items-start justify-center">
				<img
					className="sm:w-[70%] xl:w-[50%]  w-[90%] sm:h-[80%]"
					src="assets\propertyimg.jpeg"
					alt=""
					srcset=""
				/>
			</div>

			<div className="text-center mb-12 mt-20"></div>

			<div className=" flex flex-wrap px-3 mt-20 items-center pb-[200px] justify-center   gap-10 w-full">
				{awards.map((award, index) => (
					<div
						data-aos="fade-left"
						className="h-[350px] bg-black bg-opacity-10 rounded-xl py-10 relative w-[350px] bg-cover  bg-center"
						style={{ backgroundImage: `url(${award.imgSrc})` }}>
						<div className="p-6 rounded-xl w-full h-full flex flex-col items-start justify-end bg-black bg-opacity-40 top-0 absolute">
							<h3 className="text-3xl font-bold text-white mb-2">
								{award.title}
							</h3>
							<p className="text-white font-medium">{award.description}</p>
						</div>
					</div>
				))}
			</div>
		</div>
	);
};

export default AwardsPageHero;
