import React from "react";
import Aos from "aos";
import { useEffect } from "react";

import "aos/dist/aos.css";
const Lawyer = () => {
	useEffect(() => {
		Aos.init({ duration: 1000, delay: 200 });
	});
	return (
		<div className="bg-[#111b29] overflow-hidden text-white py-24 px-12 flex flex-col justify-center items-center min-h-screen gap-28">
			<div data-aos="fade-up" className="text-center max-w-6xl">
				<div className="flex flex-col items-center mb-10 md:flex-row md:items-start md:justify-center">
					<h1 className="sm:text-5xl text-3xl font-bold whitespace-nowrap">
						تعرف على محامينا
					</h1>
					<div className="flex items-center gap-4 mt-4 md:mt-0 md:ml-6">
						<img
							src="assets/Line1.png"
							alt="Golden Line"
							className="w-2 h-[40px] sm:h-auto"
						/>
						<p
							className="text-[13px] text-[#a0b1c5] sm:text-xl "
							style={{ direction: "ltr", unicodeBidi: "plaintext" }}>
							تمتلك الشركة نخبة من المجموعة الاستشارية القانونية والقانونية التي
							تعمل بشكل مكثف ومهني لتقديم خدمات قانونية متنوعة.
						</p>
					</div>
				</div>
			</div>

			<div className="flex flex-col gap-20 items-center justify-center max-w-6xl">
				<div
					data-aos="fade-up"
					className="flex flex-col md:flex-row items-center justify-center md:items-start gap-12">
					<img
						src="assets/lawyer1.jpeg"
						alt="محمد بن عبدالرحمن الرشيد"
						className="w-80 h-80 object-cover object-top rounded-lg"
					/>
					<div className="text-left">
						<h2 className="text-3xl font-bold mb-3">محامي</h2>
						<h3 className="text-4xl font-semibold text-[#f5c982] mb-5">
							محمد بن عبدالرحمن الرشيد
						</h3>
						<p
							className="text-xl text-[#a0b1c5] mb-10 max-w-2xl"
							style={{ direction: "ltr", unicodeBidi: "plaintext" }}>
							يتمتع بخبرة تزيد عن 18 عامًا، وقد عمل كخبير في إدارة الحقوق الخاصة
							ومستشار قانوني بدوام جزئي. وقد تم اختيارنا لتولي قضيتنا بتمثيل
							احترافي. كما أنه عضو في العديد من المنظمات القانونية المهنية.
						</p>
						<a href="/team">
							<button className="px-8 py-4 border border-[#f5c982] text-[#f5c982] hover:bg-[#f5c982] hover:text-[#111b29] rounded transition-all duration-300 text-left">
								اكتشف المزيد
							</button>
						</a>
					</div>
				</div>

				<div
					data-aos="fade-up"
					className="flex flex-col md:flex-row-reverse items-center justify-center md:items-start gap-12">
					<img
						src="assets/lawyer2.png"
						alt="بندر بن عبدالرحمن المسيند"
						className="w-80 h-80 object-cover object-top rounded-lg"
					/>
					<div className="text-left">
						<h2 className="text-3xl font-bold mb-3">محامي</h2>
						<h3 className="text-4xl font-semibold text-[#f5c982] mb-5">
							الدكتور بندر بن عبدالرحمن المسيند
						</h3>
						<p
							className="text-xl text-[#a0b1c5] mb-10 max-w-2xl"
							style={{ direction: "ltr", unicodeBidi: "plaintext" }}>
							يتمتع بخبرة تزيد عن 18 عامًا، عمل كمدير للشؤون القانونية وكمستشار
							في العديد من القضايا القانونية، ويحمل شهادات متخصصة في القانون
							السعودي والإدارة العامة.
						</p>
						<a href="/team">
							<button className="px-8 py-4 border border-[#f5c982] text-[#f5c982] hover:bg-[#f5c982] hover:text-[#111b29] rounded transition-all duration-300 text-left">
								اكتشف المزيد
							</button>
						</a>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Lawyer;
