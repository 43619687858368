import React, { useState, useEffect } from "react";
import { MenuIcon } from "lucide-react";
import { useLocation } from "react-router-dom";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import Aos from "aos";
import "aos/dist/aos.css";
const Navbaren = () => {
	const [menubar, setMenubar] = useState(true);
	const [isScrolled, setIsScrolled] = useState(false);
	const [textcolor, setTextcolor] = useState("text-black");
	const [isOpen, setIsOpen] = React.useState(false);
	const toggleDrawer = () => {
		setIsOpen((prevState) => !prevState);
	};
	let location = useLocation();

	function toggleMenu() {
		setMenubar(!menubar);
	}
	useEffect(() => {
		Aos.init({ duration: 1000, delay: 200 });
		if (location.pathname === "/en/awards&honors") {
			setTextcolor("text-[#73777F]");
		}
		if (location.pathname === "/en") {
			setTextcolor("text-[#73777F]");
		}
		if (location.pathname === "/en/about") {
			setTextcolor("text-white");
		}
		if (location.pathname === "/en/team") {
			setTextcolor("text-[#73777F]");
		}
		if (location.pathname === "/en/services") {
			setTextcolor("text-[#73777F]");
		}
		if (location.pathname === "/en/consultation") {
			setTextcolor("text-[#73777F]");
		}

		const handleScroll = () => {
			setIsScrolled(window.scrollY > 50); // Threshold for scroll event
		};

		window.addEventListener("scroll", handleScroll);
		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);

	return (
		<div
			data-aos="fade-in"
			className={` z-50 px-20 w-full max-[584px]:px-3 h-fit fixed top-0 flex items-center justify-between transition-all duration-300 ${
				isScrolled ? "bg-white text-black" : "bg-transparent text-white"
			}`}>
			<div className=" flex items-center justify-center  p-1">
				<img src="../assets/logo.png" className=" w-[180px]" alt="" srcset="" />
			</div>
			<div className=" text-[#73777F]  max-[980px]:pb-20 font-[700]  text-md p-3 flex items-center justify-center gap-12 mr-10  max-[1174px]:hidden">
				<a href="/en">
					<div
						className={`hover:cursor-pointer ${
							isScrolled && location.pathname !== "/en"
								? `text-[#73777F]`
								: `${
										location.pathname === "/en"
											? "text-[#001C38]"
											: `${textcolor}`
								  }`
						}`}>
						Home
					</div>
				</a>
				<a href="/en/about">
					<div
						className={`hover:cursor-pointer ${
							isScrolled && location.pathname !== "/en/about"
								? `text-[#73777F]`
								: `${
										location.pathname === "/en/about"
											? "text-[#001C38]"
											: `${textcolor}`
								  }`
						}`}>
						About
					</div>
				</a>
				<a href="/en/team">
					<div
						className={`hover:cursor-pointer ${
							isScrolled && location.pathname !== "/en/team"
								? `text-[#73777F]`
								: `${
										location.pathname === "/en/team"
											? "text-[#001C38]"
											: `${textcolor}`
								  }`
						}`}>
						Team
					</div>
				</a>
				<a href="/en/services">
					<div
						className={`hover:cursor-pointer ${
							isScrolled && location.pathname !== "/en/services"
								? `text-[#73777F]`
								: `${
										location.pathname === "/en/services"
											? "text-[#001C38]"
											: `${textcolor}`
								  }`
						}`}>
						Services
					</div>
				</a>
				<a href="/en/awards&honors">
					<div
						className={`hover:cursor-pointer ${
							isScrolled && location.pathname !== "/en/awards&honors"
								? `text-[#73777F]`
								: `${
										location.pathname === "/en/awards&honors"
											? "text-[#001C38]"
											: `${textcolor}`
								  }`
						}`}>
						Awards & Honours
					</div>
				</a>
				<div className=" flex items-center justify-center">
					<button className="font-bold px-8 py-2 bg-[#3A608F] text-white hover:bg-opacity-90 transition-all duration-200">
						<a href="/en/consultation">Contact us</a>
					</button>
					<a href="/">
						<img
							className=" hover:cursor-pointer"
							src="/assets/language2.png"
							alt=""
							srcset=""
						/>
					</a>
				</div>
			</div>
			<div className=" hidden max-[1174px]:flex relative">
				<MenuIcon
					onClick={toggleDrawer}
					className={` ${isScrolled ? "text-black" : textcolor}`}
				/>

				<Drawer
					open={isOpen}
					onClose={toggleDrawer}
					direction="left"
					className=" flex flex-col-reverse w-full">
					<div
						className={`w-full h-[80%] flex bg-white p-3 px-8 flex-col justify-center items-start gap-10 absolute top-8 text-nowrap text-md  right-2 text-[#73777F] font-bold text-2xl    ${
							menubar ? "" : "hidden"
						}`}>
						<a href="/en">
							<p
								className={` hover:cursor-pointer   ${
									location.pathname === "/en" && "text-[#001C38]"
								} `}>
								Home
							</p>
						</a>
						<a href="/en/about">
							<p
								className={` hover:cursor-pointer ${
									location.pathname === "/en/about" && "text-[#001C38]"
								} `}>
								About us
							</p>
						</a>
						<a href="/en/team">
							<p
								className={` hover:cursor-pointer ${
									location.pathname === "/en/team" && "text-[#001C38]"
								} `}>
								Team
							</p>
						</a>
						<a href="/en/services">
							<p
								className={` hover:cursor-pointer ${
									location.pathname === "/en/services" && "text-[#001C38]"
								} `}>
								Services
							</p>
						</a>
						<a href="/en/awards&honors">
							<p
								className={` hover:cursor-pointer ${
									location.pathname === "/en/awards&honors" && "text-[#001C38]"
								} `}>
								Awards & Honors
							</p>
						</a>
						<a href="/en/consultation">
							<p
								className={` hover:cursor-pointer ${
									location.pathname === "/en/consultation" && "text-[#001C38]"
								} `}>
								Contact
							</p>
						</a>
						<a href="/">
							<img
								className=" mt-32 hover:cursor-pointer"
								src="/assets/language2.png"
								alt=""
								srcset=""
							/>
						</a>
					</div>
				</Drawer>
			</div>
		</div>
	);
};

export default Navbaren;
