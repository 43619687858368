import { BrowserRouter } from "react-router-dom";
import { useRoutes } from "react-router-dom";
import HomePage from "./pages/homePage";
import AboutPage from "./pages/aboutPage";
import Navbar from "./component/navbar";
import TeamPage from "./pages/teamPage";
import ServicesPage from "./pages/servicesPage";
import AwardsPage from "./pages/awardsPage";
import ConsultationPage from "./pages/consultationPage";
import Footer from "./component/Footer";
import Testimonials from "./component/testimonials";
import HomePageen from "./pages-en/homePage-en";
import AboutPageen from "./pages-en/aboutPage-en";
import TeamPageen from "./pages-en/teamPage-en";
import ServicesPageen from "./pages-en/servicesPage-en";
import AwardsPageen from "./pages-en/awardsPage-en";
import ConsultationPageen from "./pages-en/consultationPage-en";
import Navbaren from "./component-en/navbar-en";
import Footeren from "./component-en/Footer-en";
import Testimonialsen from "./component-en/testimonials-en";
import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
function AppRoutes() {
	let routes = useRoutes([
		{ path: "", element: <HomePage /> },
		{ path: "about", element: <AboutPage /> },
		{ path: "team", element: <TeamPage /> },
		{ path: "services", element: <ServicesPage /> },
		{ path: "awards&honors", element: <AwardsPage /> },
		{ path: "consultation", element: <ConsultationPage /> },
		{ path: "en", element: <HomePageen /> },
		{ path: "en/about", element: <AboutPageen /> },
		{ path: "en/team", element: <TeamPageen /> },
		{ path: "en/services", element: <ServicesPageen /> },
		{ path: "en/awards&honors", element: <AwardsPageen /> },
		{ path: "en/consultation", element: <ConsultationPageen /> },
	]);

	return routes;
}

function App() {
	const [isEn, setIsEn] = useState(false);
	const [showTestimonials, setShowTestimonial] = useState(true);
	useEffect(() => {
		let location = window.location.pathname;
		if (location.includes("/en")) {
			setIsEn(true);
		}
		if (location === "/en/consultation") {
			setShowTestimonial(false);
		}
		if (location === "/consultation") {
			setShowTestimonial(false);
		}
	}, []);

	return (
		<div className=" overflow-hidden">
			<Helmet>
				<meta charSet="utf-8" />
				<meta property="og:image" content="/assets/logo.png"></meta>

				<meta name="og:title" content="AlRasheed & AlMusained Law Firm"></meta>
				<meta
					name="og:description"
					content="We are companies engaged in the provision of information and legal services in a professional manner, where our team includes a group of lawyers and legal advisers with outstanding scientific and practical efficiency. Our headquarters in the Eastern Region and our services cover all geographic regions of the Kingdom of Saudi Arabia.
"></meta>

				<title>AlRasheed & AlMusained Law Firm</title>
				<link rel="canonical" href="http://mysite.com/example" />
			</Helmet>
			<BrowserRouter>
				{isEn ? <Navbaren /> : <Navbar />}
				<AppRoutes />
				{/* {showTestimonials && (isEn ? <Testimonialsen /> : <Testimonials />)} */}

				{isEn ? <Footeren /> : <Footer />}
			</BrowserRouter>
		</div>
	);
}

export default App;
