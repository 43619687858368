import WhyChooseUsen from "../component-en/Whyus-en";
import Awardsen from "../component-en/Awards-en";
import Lawyeren from "../component-en/Lawyers-en";
import Consulten from "../component-en/Consult-en";
import Servicesen from "../component-en/Services-en";
import Visionen from "../component-en/Vision-en";
import Homelandingen from "../component-en/homelanding-en";
import Testimonialsen from "../component-en/testimonials-en";

const HomePageen = () => {
	return (
		<>
			<Homelandingen />
			<Visionen />
			<Servicesen />
			<WhyChooseUsen />

			<Lawyeren />
			<Awardsen />
			<Testimonialsen/>
			<Consulten />
		</>
	);
};

export default HomePageen;
