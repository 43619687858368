import React from "react";
import Aos from "aos";
import { useEffect } from "react";

import "aos/dist/aos.css";
const ServicePageHero = () => {
	useEffect(() => {
		Aos.init({ duration: 1000, delay: 200 });
	});
	return (
		<div
			style={{
				backgroundImage: `url("../assets/hero4.jpeg")`,
				backgroundPosition: "right",
				backgroundSize: "cover",
			}}
			className=" relative     w-full h-[100vh]">
			<div className="absolute inset-0  flex flex-col items-start justify-center">
				<div
					data-aos="fade-right"
					className="text-center   h-full w-full   text-white flex
                 items-start pl-40  max-[1386px]:pl-20 max-[1026px]:pl-10
                 max-[936px]:pl-[80px] max-[714px]:pl-[20px] justify-center flex-col">
					<h1 className="text-6xl text-[#001C38] max-[714px]:text-4xl font-bold  mb-8 ">
						{" "}
						حلول قانونية شاملة
					</h1>
					<p className="text-xl text-center text-[#43474E] font-[400] max-[714px]:pr-4 max-[714px]:text-lg max-[714px]:font-[200]  leading-8 mb-8">
						خدمات مصممة خصيصًا لتلبية احتياجاتك القانونية الفريدة
					</p>

					<button className="font-bold px-20 py-3 text-white text-m bg-[#001C38] hover:bg-white hover:text-black border-2 border-black transition-all duration-300">
						<a href="/consultation">طلب استشارة</a>
					</button>
				</div>
			</div>
		</div>
	);
};

export default ServicePageHero;
