import React from "react";
import Aos from "aos";
import { useEffect } from "react";

import "aos/dist/aos.css";
const Homelandingen = () => {
	useEffect(() => {
		Aos.init({ duration: 1000, delay: 200, offset: 1 });
	});
	return (
		<div className=" overflow-x-hidden w-full h-[100vh] max-[1300px]:h-fit   bg-[#F2F3FA]  flex  items-end  justify-end  gap-20 max-[1300px]:flex-col max-[1300px]:items-center    max-[1300px]:gap-5">
			<div
				data-aos="fade-right"
				className=" mt-10 hidden   max-[1300px]:flex h-fit flex-col max-[1300px]:items-center justify-center  px-3 gap-5">
				<h1
					data-aos="fade-right"
					className=" font-bold text-[50px]  text-center sm:text-[80px] max-[639px]:mt-20 leading-[95px] max-[639px]:leading-[70px]  text-[#191C20]  font-title">
					{" "}
					<br />
					Your Trusted
					<br />
					Legal Partners for a Secure Future
				</h1>
				<h1
					className=" font-sans text-lg text-[#191C20] sm:text-left text-center"
					style={{ direction: "ltr", unicodeBidi: "plaintext" }}>
					Headquartered in the Eastern Region, we proudly <br /> serve clients
					throughout all regions of the Kingdom.
				</h1>
				<button className="font-bold px-12 py-3 text-white text-lg bg-[#001C38] hover:bg-opacity-90  transition-all duration-200">
					<a href="/en/consultation">Request a Consultation</a>
				</button>
			</div>
			<div
				data-aos="fade-left"
				className=" pt-20 relative px-2 pr-[200px] max-[1536px]:pr-[50px] max-[1300px]:pr-2 ">
				<div
					data-aos-offset="-100"
					data-aos="fade-left"
					className=" max-[1300px]:hidden absolute left-[-500px] flex h-fit flex-col max-[1242px]:items-center justify-center items-start px-3 gap-5">
					<h1 className=" font-custom   font-bold text-[80px] leading-[95px]  text-[#191C20]  ">
						{" "}
						<br />
						Your Trusted
						<br />
						Legal Partners for <br /> a Secure Future
					</h1>
					<h1
						className=" font-normal text-lg text-[#191C20]"
						style={{ direction: "ltr", unicodeBidi: "plaintext" }}>
						{" "}
						Headquartered in the Eastern Region, we proudly <br /> serve clients
						throughout all regions of the Kingdom.
					</h1>
					<button className="font-bold px-12 py-3 text-white text-lg bg-[#001C38] hover:bg-white hover:text-black border-2 border-black transition-all duration-300">
						<a href="/en/consultation">Request a Consultation</a>
					</button>
				</div>
				<img src="../assets/hero1.png" alt="" srcset="" />
			</div>
		</div>
	);
};

export default Homelandingen;
