import React from "react";
import Aos from "aos";
import { useEffect } from "react";

import "aos/dist/aos.css";

const WhyChooseUs = () => {
	useEffect(() => {
		Aos.init({ duration: 1000, delay: 200 });
	});
	return (
		<div className="bg-white overflow-x-hidden min-h-screen p-8 flex justify-center items-center">
			<div className="max-w-7xl mx-auto grid grid-cols-1 lg:grid-cols-2 gap-12">
				<div data-aos="fade-right" className="flex justify-center items-center">
					<img
						src="assets/office.jpeg"
						alt="Office"
						className="w-full lg:w-[600px] h-auto rounded-lg shadow-lg object-cover md:h-[870px] sm:h-auto"
					/>
				</div>

				<div data-aos="fade-left" className="space-y-8 mt-12 lg:mt-24">
					<h2 className="text-4xl md:text-6xl font-bold text-gray-900 text-left">
						لماذا تختارنا؟
					</h2>

					<div className="grid grid-cols-1 lg:grid-cols-2 gap-8 gap-y-12">
						<div className="space-y-2">
							<span className="text-xl ">01</span>
							<h3 className="text-xl font-bold text-gray-900">
								الخبرة المحلية الموثوقة
							</h3>
							<p className="text-lg text-gray-700">
								يكون فريقنا من محامين سعوديين مطلعين على عمق المشهد القانوني
								المحلّي، مما يضمن حصولك على الإرشادات الحقيقية لتحقيق احتياجاتك
								القانونية
							</p>
						</div>

						<div className="space-y-2">
							<span className="text-xl">02</span>
							<h3 className="text-xl font-bold text-gray-900">
								حلول قانونية مخصصة
							</h3>
							<p className="text-lg text-gray-700">
								نحن نقدم استراتيجيات قانونية مميزة تركز على الحلول المصممة
								خصيصاً لتلبية متطلبات العميل القانونية المختلفة
							</p>
						</div>

						<div className="space-y-2">
							<span className="text-xl ">03</span>
							<h3 className="text-xl font-bold text-gray-900">
								نجاح مؤكد في القانون السعودي
							</h3>
							<p className="text-lg text-gray-700">
								بفضل سجل حافل من النتائج القانونية المتميزة، نتمتع بخبرة واسعة
								في التفاوض وتسوية النزاعات القانونية المعقدة في المملكة العربية
								السعودية
							</p>
						</div>

						<div className="space-y-2">
							<span className="text-xl ">04</span>
							<h3 className="text-xl font-bold text-gray-900">
								الثبات في النزاهة والتميز
							</h3>
							<p className="text-lg text-gray-700">
								إن النزاهة والشفافية والتميز في تقديم الخدمات القانونية هو
								العامل الأساسي الذي يضمن تفوقنا واستمراريتنا في تقديم الخدمات
								على أعلى مستوى
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default WhyChooseUs;
