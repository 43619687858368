import React from "react";
import Aos from "aos";
import { useEffect } from "react";
import "aos/dist/aos.css";

const Vision2 = () => {
	useEffect(() => {
		Aos.init({ duration: 1000, delay: 200 });
	});
	return (
		<div
			className="relative w-full h-[600px] bg-cover bg-center flex justify-center items-center py-24 px-4 md:p-28 lg:p-36"
			style={{ backgroundImage: `url('assets/Vision section2.png')` }}>
			<div
				data-aos="slide-right"
				className="text-white text-left p-4 max-w-7xl gap-8 flex flex-col">
				<h1 className="text-[45px] sm:text-xl md:text-[50px] lg:text-[60px] font-bold leading-relaxed">
					رؤيتنا
				</h1>
				<p
					className="text-[20px] sm:text-xl md:text-[22px] lg:text-[25px] leading-relaxed "
					style={{
						lineHeight: "1.5",
						direction: "ltr",
						unicodeBidi: "plaintext",
					}}>
					نسعى دائمًا إلى ترسيخ مهنة المحاماة في المجتمع السعودي وتوعية الأفراد
					والجهات بأهمية الحفاظ على حقوقهم التي يكفلها القانون والقانون من خلال
					تلبية الاحتياجات القانونية التي تلبي رغبات المستفيدين وتمكينهم من بدء
					عملهم في . بطريقة توفر لهم الحماية القانونية المناسبة.
				</p>
			</div>
		</div>
	);
};

export default Vision2;
