import React from "react";
import Aos from "aos";
import { useEffect } from "react";

import "aos/dist/aos.css";
const Lawyeren = () => {
	useEffect(() => {
		Aos.init({ duration: 1000, delay: 200 });
	});
	return (
		<div className="bg-[#111b29] overflow-hidden text-white py-24 px-12 flex flex-col justify-center items-center min-h-screen gap-28">
			<div data-aos="fade-up" className="text-center max-w-6xl">
				<div className="flex flex-col items-center mb-10 md:flex-row md:items-start md:justify-center">
					<h1 className="sm:text-5xl font-custom text-3xl font-bold whitespace-nowrap">
						Meet Our Lawyers
					</h1>
					<div className="flex items-center gap-4 mt-4 md:mt-0 md:ml-6">
						<img
							src="assets/Line1.png"
							alt="Golden Line"
							className="w-2 h-[40px] sm:h-auto"
						/>
						<p
							className="text-[13px] text-[#a0b1c5] sm:text-xl "
							style={{ direction: "ltr", unicodeBidi: "plaintext" }}>
							The company has an elite legal and legal consultancy group that
							works in a consistent and professional manner to provide a variety
							of legal services.
						</p>
					</div>
				</div>
			</div>

			<div className="flex flex-col gap-20 items-center justify-center max-w-6xl">
				<div
					data-aos="fade-up"
					className="flex flex-col md:flex-row items-center justify-center md:items-start gap-12">
					<img
						src="assets/lawyer1.jpeg"
						alt="محمد بن عبدالرحمن الرشيد"
						className="w-80 h-80 object-cover object-top rounded-lg"
					/>
					<div className="text-left">
						<h2 className="text-3xl font-bold mb-3">Attorney</h2>
						<h3 className="text-4xl font-semibold text-[#f5c982] mb-5">
							Mr. Mohammed bin Abdulrahman Al-Rasheed
						</h3>
						<p
							className="text-xl text-[#a0b1c5] mb-10 max-w-2xl"
							style={{ direction: "ltr", unicodeBidi: "plaintext" }}>
							With over 17 years of experience, he has served as a researcher in
							private rights administration and as a part-time legal advisor. He
							has completed advanced legal training and is actively involved in
							several professional legal organizations, including the Saudi
							Lawyers Association and the Eastern Chamber’s Committee of Lawyers
							and Legal Advisers.
						</p>
						<a href="/en/team">
							<button className="px-8 py-4 border border-[#f5c982] text-[#f5c982] hover:bg-[#f5c982] hover:text-[#111b29] rounded transition-all duration-300 text-left">
								Find Out More
							</button>
						</a>
					</div>
				</div>

				<div
					data-aos="fade-up"
					className="flex flex-col md:flex-row-reverse items-center justify-center md:items-start gap-12">
					<img
						src="assets/lawyer2.png"
						alt="بندر بن عبدالرحمن المسيند"
						className="w-80 h-80 object-cover object-top rounded-lg"
					/>
					<div className="text-left">
						<h2 className="text-3xl font-bold mb-3">Attorney</h2>
						<h3 className="text-4xl font-semibold text-[#f5c982] mb-5">
							Dr. Bandar bin Abdul Rahman Almusained
						</h3>
						<p
							className="text-xl text-[#a0b1c5] mb-10 max-w-2xl"
							style={{ direction: "ltr", unicodeBidi: "plaintext" }}>
							With over 18 years of experience, he has worked as a researcher in
							private rights administration and as a legal advisor at the Saudi
							Electricity Company. He is a certified arbitrator in commercial
							and civil cases and a key member of the Saudi Bar Association. He
							has completed specialized courses with Naif Arab University for
							Security Sciences, the Institute of Public Administration, and the
							Arab Organization for Administrative Development.
						</p>
						<a href="/en/team">
							<button className="px-8 py-4 border border-[#f5c982] text-[#f5c982] hover:bg-[#f5c982] hover:text-[#111b29] rounded transition-all duration-300 text-left">
								Find Out More
							</button>
						</a>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Lawyeren;
