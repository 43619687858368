import React from 'react';
import Aos from "aos";
import { useEffect } from "react";
import 'aos/dist/aos.css'
import {
    Carousel,
    CarouselContent,
    CarouselItem,
    CarouselNext,
    CarouselPrevious,
} from './carousel';
const Testimonials = () => {
    useEffect(() => {
        Aos.init({ duration: 1000, delay: 200 })
    })

    const data = [
        { img: "/assets/testimonial1.jpeg", text: "كانت خبرتهم في مجال التمويل وقانون الشركات واضحة منذ البداية.لقد قدم المحامون نصائح واضحة وفعالة بشأن مسائل الاستثمار والاندماج، والتي كانت حاسمة لنجاح معاملاتي.", name: "خالد الجابري", profession: "مهندس معماري" },
        { img: "/assets/testimonial2.jpeg", text: "إن النصيحة القانونية التي تلقيتها كانت لا تقدر بثمن.تعامل المحامون مع قضيتي بكفاءة كبيرة وتأكدوا من حماية حقوقي في جميع الأوقات.أوصي بشدة بخدماتهم لأي شخص يحتاج إلى مشورة قانونية موثوقة .", name: "طارق الحربي", profession: "طبيب" },
        { img: "/assets/testimonial3.jpeg", text: "كانت خبرتهم في مجال التمويل وقانون الشركات واضحة منذ البداية.لقد قدم المحامون نصائح واضحة وفعالة بشأن مسائل الاستثمار والاندماج، والتي كانت حاسمة لنجاح معاملاتي.", name: "عمر المنصوري", profession: "مستشار مالي" },
        { img: "/assets/testimonial3.jpeg", text: "كانت خبرتهم في مجال التمويل وقانون الشركات واضحة منذ البداية.لقد قدم المحامون نصائح واضحة وفعالة بشأن مسائل الاستثمار والاندماج، والتي كانت حاسمة لنجاح معاملاتي.", name: "عمر المنصوري", profession: "مستشار مالي" },



    ]
    return (
        <div className='  w-full bg-[#111b29] overflow-hidden  h-fit py-14 pb-20 flex flex-col items-center justify-center'>
            <h1 data-aos="fade-down" className=' text-center pt-[30px] px-3 text-white font-bold text-5xl'>الشهادات</h1>
            <p data-aos="fade-down" className='text-center  px-3 pt-2 text-white font-normal text-lg'>ما يقوله عملاؤنا</p>

            <Carousel data-aos="fade-left" className="w-full mb-10  mt-10 flex items-center justify-center ">
                <CarouselContent className='w-full  ' >

                    {
                        data.map((data) => {
                            return (
                                <CarouselItem className="   min-[868px]-1/2 min-[1386px]:basis-1/3" >
                                    <TestimonialCards text={data.text} img={data.img} name={data.name} profession={data.profession} />
                                </CarouselItem>
                            )
                        })
                    }
                </CarouselContent>
                <CarouselPrevious />
                <CarouselNext />
            </Carousel>



        </div>
    );
}

export default Testimonials;




const TestimonialCards = ({ text, name, profession, img }) => {
    return (
        <div className=' bg-[#111b29] p-8 border-red-50  border  rounded-xl h-fit w-[400px] '>
            <div className=' w-full flex  items-center  justify-between'>
                <img src="/assets/qouta.png" alt="" srcset="" />
                <img src={img} className='  rounded-bl-full h-[120px] w-[150px] ' alt="" srcset="" />

            </div>
            <p className=' text-white font-normal text-lg mt-10'>{text}</p>
            <div className=' w-full mt-14 flex flex-col text-left'>
                <p className=' text-white font-bold text-lg mt-10'>{name}</p>
                <p className=' text-white font-[400] text-lg '>{profession}</p>

            </div>

        </div>
    );
}



