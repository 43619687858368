import React from "react";
import TeamPageHero from "../component/teamPageHero";
import Awards from "../component/Awards";
import Consult from "../component/Consult";
import Founder from "../component/Founder";
import Testimonials from "../component/testimonials";
const TeamPage = () => {
	return (
		<>
			<TeamPageHero />
			<Founder />
			<Awards />
			<Testimonials/>
			<Consult />
		</>
	);
};

export default TeamPage;
