import React from "react";
import AwardsPageHero from "../component/awardsPageHero";
import Consult from "../component/Consult";
import Testimonials from "../component/testimonials";
const AwardsPage = () => {
	return (
		<>
			<AwardsPageHero />
			<Testimonials />

			<Consult />
		</>
	);
};

export default AwardsPage;
