import React from "react";
import Aos from "aos";
import { useEffect } from "react";

import "aos/dist/aos.css";
const TeamPageHeroen = () => {
	useEffect(() => {
		Aos.init({ duration: 1000, delay: 200 });
	});
	return (
		<div
			style={{
				backgroundImage: `url("../assets/hero3.png")`,
				backgroundPosition: "center",
				backgroundSize: "cover",
			}}
			className=" relative  w-full h-[100vh]">
			<div className="absolute inset-0 bg-black bg-opacity-10 flex flex-col items-center justify-center">
				<div
					className="text-center   bg-[#f0f0f0] h-full w-full  bg-opacity-70 text-white flex
                 items-center 
                 max-[936px]:pl-[80px] max-[714px]:pl-[20px] justify-center flex-col">
					<h1
						data-aos="fade-down"
						className="text-6xl font-custom text-[#001C38] max-[714px]:text-4xl font-bold  mb-8 ">
						Meet Our <br />
						Expert Legal Team
					</h1>
					<p
						data-aos="fade-down"
						className="text-xl text-center text-[#43474E] font-[400] max-[714px]:pr-4 max-[714px]:text-lg max-[714px]:font-[200]  leading-8 mb-8">
						Dedicated Professionals Committed to Excellence and Integrity in
						Every Case
					</p>

					<button className="font-bold px-12 py-3 text-white text-lg bg-[#001C38] hover:bg-white hover:text-black border-2 border-black transition-all duration-300">
						<a href="/en/consultation">Request a Consultation</a>
					</button>
				</div>
			</div>
		</div>
	);
};

export default TeamPageHeroen;
