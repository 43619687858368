import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";

const Vision = () => {
	useEffect(() => {
		Aos.init({ duration: 1000, delay: 200 });
	}, []);

	return (
		<div
			className="relative w-full bg-cover bg-center flex justify-center items-center px-8 py-10 md:py-28 lg:py-36 h-[50vh] sm:h-[80vh] md:h-[60vh]"
			style={{ backgroundImage: `url('assets/Vision-section.png')` }}>
			<div
				data-aos="fade-down"
				className="text-white max-w-7xl text-center sm:text-right">
				<p
					className="text-3xl sm:text-3xl md:text-4xl lg:text-5xl font-bold leading-relaxed"
					style={{ direction: "ltr", unicodeBidi: "plaintext" }}>
					نهدف إلى الارتقاء بمهنة المحاماة في المجتمع السعودي من خلال تثقيف
					وتمكين الأفراد والشركات لحماية حقوقهم والعمل بثقة قانونية.
				</p>
			</div>
		</div>
	);
};

export default Vision;
