import React, { useRef, useState, useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import emailjs from "@emailjs/browser";

const Contactform = () => {
    const form = useRef();
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        consultation: ''
    });

    useEffect(() => {
        Aos.init({ duration: 1000, delay: 200 });
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const sendEmail = async (e) => {
        e.preventDefault();
        setLoading(true);
    
        try {
            const response = await emailjs.send(
                // process.env.NEXT_PUBLIC_SERVICE_ID,  //
                'service_5czxz0c',
				// process.env.NEXT_PUBLIC_TEMPLATE_ID, //
                'template_rmlf', 
				//form.current,
                {
                     from_name: formData.name,
                    message: `Name: ${formData.name}\nEmail: ${formData.email}\nPhone: ${formData.phone}\nConsultation: ${formData.consultation}`,              
                },
                // process.env.NEXT_PUBLIC_PUBLIC_KEY //
                '9mz6iTRVggEqUzVTt'
            );
    
            console.log("EmailJS Response:", response);
    
            alert("Success! Email Sent.");
            setFormData({ name: '', email: '', phone: '', consultation: '' });
        } catch (error) {
            console.error("Error sending email:", error);
            alert("Error sending email. Check console for details.");
        } finally {
            setLoading(false);
        }
    
    };

    return (
        <form data-aos="fade-left" ref={form} onSubmit={sendEmail} className='h-fit w-[500px] z-20 bg-[#001C38] flex flex-col gap-8 p-5 px-8 py-10 items-center justify-center max-[580px]:w-full'>
            <input type="text" name="name" placeholder="اسمك" value={formData.name} onChange={handleChange} className='mt-5 bg-[#001C38] font-medium w-full outline-none h-5 py-6 active:ring-0 text-white border-b-[1px] border-red-50' required />
            <input type="email" name="email" placeholder="بريدك الإلكتروني" value={formData.email} onChange={handleChange} className='bg-[#001C38] font-medium w-full outline-none h-5 py-6 active:ring-0 text-white border-b-[1px] border-red-50' required />
            <input type="tel" name="phone" placeholder="رقم هاتفك" value={formData.phone} onChange={handleChange} className='bg-[#001C38] font-medium w-full outline-none h-5 py-6 active:ring-0 text-white border-b-[1px] border-red-50' required />
            <input type="text" name="consultation" placeholder="التشاور" value={formData.consultation} onChange={handleChange} className='bg-[#001C38] font-medium w-full outline-none h-5 py-6 active:ring-0 text-white border-b-[1px] border-red-50' required />
            <button type="submit" disabled={loading} className={`font-bold px-24 w-full py-2 my-5 bg-white text-[#001C38] hover:opacity-90 transition-all duration-200 ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}>
                {loading ? "جاري الإرسال..." : "طلب استشارة"}
            </button>
        </form>
    );
};

export default Contactform;
