import React from "react";
import Aos from "aos";
import { useEffect } from "react";

import "aos/dist/aos.css";
const Awards = () => {
	useEffect(() => {
		Aos.init({ duration: 1000, delay: 200 });
	});
	return (
		<div className="bg-white min-h-screen overflow-hidden p-8 flex justify-center flex-col gap-24">
			<div data-aos="fade-down" className="flex items-center flex-col gap-10">
				<div className="text-center  text-6xl font-bold items-center flex">
					<h1>الجوائز والأوسمة</h1>
				</div>
				<div className="w-auto text-center">
					<p style={{ direction: "ltr", unicodeBidi: "plaintext" }}>
						لقد تم الاعتراف بالتزامنا بالتميز القانوني من خلال العديد من الجوائز
						والأوسمة.
						<br />
						تعكس هذه الجوائز تفانينا في تقديم خدمات قانونية استثنائية وسعينا
						الدؤوب لتحقيق أعلى المعايير في الصناعة.
					</p>
				</div>
				<div>
					<button className="font-bold sm:px-16 px-12 py-3 border-4 border-black text-black bg-white hover:bg-black hover:text-white transition-all duration-300">
						<a href="/awards&honors">شاهد جميع الجوائز</a>
					</button>
				</div>
			</div>

			<div data-aos="fade-up" className="flex justify-center">
				<img
					src="/assets/award.png"
					alt="Awards"
					className="h-[300px] w-[100%] sm:h-[400px] md:h-[500px] lg:h-[700px] lg:w-[55%]"
				/>
			</div>
		</div>
	);
};

export default Awards;
