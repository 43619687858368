import React from "react";
import AwardsPageHeroen from "../component-en/awardsPageHero-en";
import Consulten from "../component-en/Consult-en";
import Testimonialsen from "../component-en/testimonials-en";
const AwardsPageen = () => {
	return (
		<>
			<AwardsPageHeroen />
			<Testimonialsen/>

			<Consulten />
		</>
	);
};

export default AwardsPageen;
