import React from 'react';
import ServicePageHero from '../component/servicePageHero';
import Awards from "../component/Awards";
import Consult from "../component/Consult";
import PracticeAreas from '../component/PracticeArea';
import Testimonials from "../component/testimonials";
const ServicesPage = () => {
    return (
        <>
            <ServicePageHero />
            <PracticeAreas />
            <Awards />
            <Testimonials/>
            <Consult />
        </>
    );
}

export default ServicesPage;
