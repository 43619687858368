import WhyChooseUs from "../component/Whyus";
import Awards from "../component/Awards";
import Lawyer from "../component/Lawyers";
import Consult from "../component/Consult";
import Services from "../component/Services";
import Vision from "../component/Vision";
import Homelanding from "../component/homelanding";


const HomePage = () => {
	return (
		<>
			<Homelanding />
			<Vision />
			<Services />
			<WhyChooseUs />
			<Lawyer />
			<Awards />
	
			<Consult />
		</>
	);
};

export default HomePage;
