import React from "react";
import Aos from "aos";
import { useEffect } from "react";
import "aos/dist/aos.css";

const PracticeAreasen = () => {
	useEffect(() => {
		Aos.init({ duration: 1000, delay: 200 });
	}, []);

	return (
		<div className="p-8 overflow-hidden bg-gray-100 flex flex-col items-center">
			<div className="flex flex-col items-start w-auto">
				<h1
					data-aos="fade-left"
					className="text-4xl font-custom text-blue-900 font-bold mb-8">
					Areas Of Practice
				</h1>

				<div
					data-aos="fade-left"
					className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10 justify-center">
					{/* Card 1 - Arbitration */}
					<div className="bg-white p-6 rounded-lg w-80 h-90 flex flex-col items-start">
						<img
							src="/assets/Vector.png"
							alt="Arbitration"
							className="rounded-lg mb-4"
						/>
						<h3 className="text-2xl text-blue-900 font-semibold mb-4">
							Arbitration
						</h3>
						<p className="text-gray-600 leading-relaxed">
							Arbitration quickly resolves local and international disputes with
							straightforward procedures. Our expert team is skilled in acting
							as arbitrators or representing clients before arbitral tribunals.
						</p>
					</div>

					{/* Card 2 - Insurance */}
					<div className="bg-white p-6 rounded-lg w-80 h-90 flex flex-col items-start">
						<img
							src="/assets/Vector-1.png"
							alt="Insurance"
							className="rounded-lg mb-4"
						/>
						<h3 className="text-2xl text-blue-900 font-semibold mb-4">
							Insurance
						</h3>
						<p className="text-gray-600 leading-relaxed">
							Insurance issues are widespread concerns to individuals and
							entities. Therefore, our team offers various legal services
							relating to insurance issues.
						</p>
					</div>

					{/* Card 3 - Banking Operations */}
					<div className="bg-white p-6 rounded-lg w-80 h-90 flex flex-col items-start">
						<img
							src="/assets/Vector-2.png"
							alt="Banking Operations"
							className="rounded-lg mb-4"
						/>
						<h3 className="text-2xl text-blue-900 font-semibold mb-4">
							Banking Operations
						</h3>
						<p className="text-gray-600 leading-relaxed">
							Our team offers legal services for banking activities, including
							project financing, Islamic finance, loan restructuring, and
							acquisitions.
						</p>
					</div>

					{/* Card 4 - Companies & Business Activities */}
					<div className="bg-white p-6 rounded-lg w-80 h-90 flex flex-col items-start">
						<img
							src="/assets/Vector-3.png"
							alt="Companies"
							className="w-12 h-12 object-cover rounded-lg mb-4"
						/>
						<h3 className="text-2xl text-blue-900 font-semibold mb-4">
							Companies & Business Activities
						</h3>
						<p className="text-gray-600 leading-relaxed">
							Our legal team offers top-notch services for company formation and
							reorganization, as well as compliance with regulations.
						</p>
					</div>

					{/* Card 5 - Work and Staff */}
					<div className="bg-white p-6 rounded-lg w-80 h-90 flex flex-col items-start">
						<img
							src="/assets/Vector-4.png"
							alt="Work and Staff"
							className="rounded-lg mb-4"
						/>
						<h3 className="text-2xl text-blue-900 font-semibold mb-4">
							Work and Staff
						</h3>
						<p className="text-gray-600 leading-relaxed">
							Our team offers legal services related to labor systems, including
							drafting and reviewing labor contracts.
						</p>
					</div>

					{/* Card 6 - Litigation */}
					<div className="bg-white p-6 rounded-lg w-80 h-90 flex flex-col items-start">
						<img
							src="/assets/Vector-5.png"
							alt="Litigation"
							className="rounded-lg mb-4"
						/>
						<h3 className="text-2xl text-blue-900 font-semibold mb-4">
							Litigation
						</h3>
						<p className="text-gray-600 leading-relaxed">
							Our team represents clients in all types of courts, including
							public, commercial, administrative, and criminal.
						</p>
					</div>

					{/* Card 7 - Real Estate & Real Estate Financing */}
					<div className="bg-white p-6 rounded-lg w-80 h-90 flex flex-col items-start">
						<img
							src="/assets/Vector-6.png"
							alt="Real Estate"
							className="rounded-lg mb-4"
						/>
						<h3 className="text-2xl text-blue-900 font-semibold mb-4">
							Real Estate & Real Estate Financing
						</h3>
						<p className="text-gray-600 leading-relaxed">
							Our team provides expert legal services in real estate law,
							including contract drafting, financing, and agreements.
						</p>
					</div>

					{/* Card 8 - Intellectual Property */}
					<div className="bg-white p-6 rounded-lg w-80 h-90 flex flex-col items-start">
						<img
							src="/assets/Vector-7.png"
							alt="Intellectual Property"
							className="rounded-lg mb-4"
						/>
						<h3 className="text-2xl text-blue-900 font-semibold mb-4">
							Intellectual Property
						</h3>
						<p className="text-gray-600 leading-relaxed">
							We provide legal services on trademark issues, copyrights, and
							intellectual property protection.
						</p>
					</div>


					<div className="bg-white p-6 rounded-lg w-80 h-90 flex flex-col items-start">
						<img
							src="/assets/Vector-8.png"
							alt="Shares"
							className="rounded-lg mb-4"
						/>
						<h3 className="text-2xl text-blue-900 font-semibold mb-4">
							Companies, Shares & Equity Shares
						</h3>
						<p className="text-gray-600 leading-relaxed">
							We offer legal services for mergers, acquisitions, joint ventures,
							and share offerings.
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PracticeAreasen;
