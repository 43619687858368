import React from "react";

const Location = () => {
	return (
		<div className="w-full flex flex-col items-start gap-4 mt-10 px-4">
			<div className="w-full h-80">
				<iframe
					title="Location Map"
					src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3575.27171165369!2d50.15299687541999!3d26.350072376987043!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjbCsDIxJzAwLjMiTiA1MMKwMDknMjAuMSJF!5e0!3m2!1sen!2s!4v1733746809467!5m2!1sen!2s"
					width="100%"
					height="100%"
					style={{ border: 0 }}
					allowFullScreen
					loading="lazy"
					referrerPolicy="no-referrer-when-downgrade"></iframe>
			</div>
		</div>
	);
};

export default Location;
