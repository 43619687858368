import React from "react";
import Contactformen from "../component-en/contactform-en";
import Aos from "aos";
import { useEffect } from "react";
import "aos/dist/aos.css";
import Location from "../component-en/location-en";

const ConsultationPageen = () => {
	let data = [
		{ img: "../assets/callicondark.png", text: "0138188000" },
		{
			img: "../assets/locationicondark.png",
			text: "Princ Ahmed St. 7560 Al Qusur Dist. 3872 Dhahran Kingdom of Saudi Arabia P.O. Box 34247",
		},
		{ img: "../assets/mailicondark.png", text: "mohammed@rmlfco.com" },
	];
	useEffect(() => {
		Aos.init({ duration: 1000, delay: 200 });
	});
	return (
		<>
			<div
				style={{
					backgroundImage: `url("../assets/consult-bg.jpeg")`,
					backgroundPosition: "center",
					backgroundSize: "cover",
				}}
				className=" bg-[#f0f0f0]  bg-opacity-70   w-full h-[100vh] max-[980px]:h-fit ">
				<div className="bg-[#f0f0f0] bg-opacity-60 flex items-center justify-evenly h-full w-full px-8 z-20 gap-20 max-[980px]:flex-col max-[980px]:h-auto max-[980px]:pt-40 max-[980px]:pb-20">
					<div
						data-aos="fade-right"
						className="flex flex-col items-start justify-start gap-5 z-20">
						<h1 className="text-6xl font-custom font-bold text-[#001C38] mb-8">
							Request a Consultation
						</h1>
						{data.map((item, index) => (
							<ConsultationInfo key={index} img={item.img} text={item.text} />
						))}
					</div>
					<Contactformen />
				</div>
			</div>
			<div>
				<Location />
			</div>
		</>
	);
};

export default ConsultationPageen;

const ConsultationInfo = ({ text, img }) => {
	return (
		<div className=" flex items-start justify-start gap-3">
			<img src={img} alt="" srcset="" />
			<p className=" font-semibold">{text}</p>
		</div>
	);
};
