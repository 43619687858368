import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faPhone,
	faEnvelope,
	faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons";
import { faTwitter, faInstagram } from "@fortawesome/free-brands-svg-icons";
import Aos from "aos";
import { useEffect } from "react";
import "aos/dist/aos.css";

const Footeren = () => {
	useEffect(() => {
		Aos.init({ duration: 1000, delay: 200 });
	}, []);
	return (
		<footer
			data-aos="fade-in"
			className="bg-gray-100 text-gray-600 pt-10 px-4 md:px-8 lg:px-16 pb-6">
			<div className="border-t border-gray-300 w-full mb-8 "></div>

			<div className="container mx-auto grid grid-cols-1 md:grid-cols-3 gap-8 md:h-[270px] h-[450px]">
				<div className="text-left">
					<img
						src="/assets/logo.png"
						alt="AlRasheed & AlMusaind Law Firm"
						className="w-36 md:w-48 mb-4"
					/>
					<p
						className="text-sm"
						style={{ direction: "ltr", unicodeBidi: "plaintext" }}>
						With a strong local presence and a global perspective, we offer a
						wide range of legal services to clients across Saudi Arabia.
					</p>
				</div>

				<div className="hidden md:flex flex-col items-start space-y-2">
					<h3 className="text-2xl font-bold mb-4">Resources</h3>
					<a href="#" className="hover:text-gray-800">
						Home
					</a>
					<a href="#" className="hover:text-gray-800">
						About us{" "}
					</a>
					<a href="#" className="hover:text-gray-800">
						Team
					</a>
					<a href="#" className="hover:text-gray-800">
						Services
					</a>
					<a href="#" className="hover:text-gray-800">
						Awards & Honors
					</a>
				</div>

				<div className="flex flex-col space-y-4 text-left">
					<h3 className="text-2xl font-bold mb-4">Contact us</h3>

					<div className="flex items-center space-x-4">
						<FontAwesomeIcon icon={faPhone} className="text-gray-600 w-5 h-5" />
						<a href="tel:+96638411961" className="hover:text-gray-800">
							0138188000
						</a>
					</div>

					<div className="flex items-center space-x-4">
						<FontAwesomeIcon
							icon={faEnvelope}
							className="text-gray-600 w-5 h-5"
						/>
						<a
							href="mailto:mohammed@rmlfco.com"
							className="hover:text-gray-800">
							mohammed@rmlfco.com
						</a>
					</div>

					<div className="flex items-center space-x-4 text-left">
						<FontAwesomeIcon
							icon={faMapMarkerAlt}
							className="text-gray-600 w-5 h-5"
						/>
						<p className="leading-tight">
							Prince Ahmed St. 7560 Al Qusur Dist.
							<br />
							P.O. Box 34247, Dhahran 3872, Kingdom of Saudi Arabia
						</p>
					</div>
				</div>
			</div>

			<div className="border-t border-gray-300 mt-8 pt-4 flex flex-col md:flex-row justify-between items-center text-gray-500 text-sm">
				<div className="mb-4 md:mb-0 text-left">
					© All rights for AlRasheed & AlMusained Company 2025
				</div>

				<div className="flex space-x-4">
					<a
						href="https://x.com/rmlfco"
						target="_blank"
						className="text-gray-500 hover:text-gray-800">
						<FontAwesomeIcon icon={faTwitter} className="w-5 h-5" />
					</a>
					<a
						href="https://www.instagram.com/rmlfco"
						target="_blank"
						className="text-gray-500 hover:text-gray-800">
						<FontAwesomeIcon icon={faInstagram} className="w-5 h-5" />
					</a>
				</div>
			</div>
		</footer>
	);
};

export default Footeren;
