import React from "react";
import Aos from "aos";
import { useEffect } from "react";

import "aos/dist/aos.css";
const Consult = () => {
	useEffect(() => {
		Aos.init({ duration: 1000 });
	});
	return (
		<div
			data-aos="fade-down"
			className="h-screen overflow-x-hidden flex justify-center items-center bg-cover bg-center relative"
			style={{
				backgroundImage:
					`url("/assets/consult.jpeg")`,
			}}>
			<div className="absolute inset-0 bg-black bg-opacity-50 flex flex-col items-center justify-center">
				<div data-aos="fade-down" className="text-center text-white ">
					<h1 className="text-5xl sm:text-6xl font-bold mb-4">
						احصل على استشارة شخصية
					</h1>
					<p className="sm:text-2xl mb-8 text-xl">
						إرشادات مخصصة لاحتياجاتك القانونية
					</p>

					<button className="font-bold px-24 py-3  text-white bg-[#001C38] hover:bg-white hover:text-black transition-all duration-300">
						<a href="/consultation">اتصل بنا</a>
					</button>
				</div>
			</div>
		</div>
	);
};

export default Consult;
