import React from "react";
import AboutpageHeroen from "../component-en/aboutpageHero-en";
import Teamen from "../component-en/Team-en";
import Awardsen from "../component-en/Awards-en";
import Consulten from "../component-en/Consult-en";
import Vision2en from "../component-en/Vision2-en";
import Testimonialsen from "../component-en/testimonials-en";
const AboutPageen = () => {
	return (
		<>
			<AboutpageHeroen />
			<Vision2en />
			<Teamen />
			<Awardsen />
			<Testimonialsen/>
			<Consulten />
		</>
	);
};

export default AboutPageen;
