import React from "react";
import Contactform from "../component/contactform";
import Aos from "aos";
import { useEffect } from "react";
import Location from "../component/location";

import "aos/dist/aos.css";
const ConsultationPage = () => {
	useEffect(() => {
		Aos.init({ duration: 1000, delay: 200 });
	});
	let data = [
		{ img: "../assets/callicondark.png", text: "0138188000" },
		{
			img: "../assets/locationicondark.png",
			text: "رقم الهاتف: ٠١٣٨١٨٨٠٠٠ طريق الأمير أحمد ٧٥٦٠ حي القصور ٣٨٧٢ ص.ب. ٣٤٢٤٧ الظهران المملكة العربية السعودية",
		},
		{ img: "../assets/mailicondark.png", text: "mohammed@rmlfco.com" },
	];
	return (
		<>
			<div
				style={{
					backgroundImage: `url("../assets/consult-bg.jpeg")`,
					backgroundPosition: "center",
					backgroundSize: "cover",
				}}
				className=" bg-[#f0f0f0]  bg-opacity-70   w-full h-[100vh] max-[980px]:h-fit ">
				<div className="bg-[#f0f0f0] bg-opacity-70 flex items-center justify-evenly h-full w-full px-8 z-20 gap-20 max-[980px]:flex-col max-[980px]:h-auto max-[980px]:pt-40 max-[980px]:pb-20">
					<div
						data-aos="fade-right"
						className="flex flex-col items-start justify-start gap-5 z-20">
						<h1 className="text-6xl font-bold text-[#001C38] mb-8">
							طلب استشارة
						</h1>
						{data.map((item, index) => (
							<ConsultationInfo key={index} img={item.img} text={item.text} />
						))}
					</div>
					<Contactform />
				</div>
			</div>
			<Location />
		</>
	);
};

export default ConsultationPage;

const ConsultationInfo = ({ text, img }) => {
	return (
		<div className=" flex items-start justify-start gap-3">
			<img src={img} alt="" srcset="" />
			<p className=" font-semibold">{text}</p>
		</div>
	);
};
