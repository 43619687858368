import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";

const PracticeAreas = () => {
	useEffect(() => {
		Aos.init({ duration: 1000, delay: 200 });
	}, []);

	return (
		<div className="p-8 overflow-hidden bg-gray-100 flex flex-col items-center">
			<div className="flex flex-col items-start w-auto">
				<h1
					data-aos="fade-left"
					className="text-4xl text-blue-900 font-bold mb-8">
					مجالات الممارسة
				</h1>

				<div
					data-aos="fade-left"
					className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-10 justify-center">
					<div className="bg-white p-6 rounded-lg w-80 h-80 flex flex-col items-start">
						<img
							src="/assets/Vector.png"
							alt="Arbitration"
							className="rounded-lg mb-4"
						/>
						<h3 className="text-2xl text-blue-900 font-semibold mb-4">
							التحكيم
						</h3>
						<p className="text-gray-600 leading-relaxed">
							يعمل التحكيم على حل النزاعات المحلية والدولية من خلال اتفاق
							الأطراف. يتمتع فريقنا بخبرة تمثيل العملاء أمام هيئات التحكيم
						</p>
					</div>

					<div className="bg-white p-6 rounded-lg w-80 h-80 flex flex-col items-start">
						<img
							src="/assets/Vector-1.png"
							alt="Insurance"
							className="rounded-lg mb-4"
						/>
						<h3 className="text-2xl text-blue-900 font-semibold mb-4">تأمين</h3>
						<p className="text-gray-600 leading-relaxed">
							نعبر قضايا التأمين من القضايا واسعة النطاق. نتولى تمثيل الأفراد
							والشركات بالإضافة إلى تقديم خدمات قانونية متعلقة بمجالات التأمين
						</p>
					</div>

					<div className="bg-white p-6 rounded-lg w-80 h-80 flex flex-col items-start">
						<img
							src="/assets/Vector-2.png"
							alt="Banking Operations"
							className="rounded-lg mb-4"
						/>
						<h3 className="text-2xl text-blue-900 font-semibold mb-4">
							العمليات المصرفية
						</h3>
						<p className="text-gray-600 leading-relaxed">
							يقدم فريقنا الخدمات القانونية المتعلقة بالعمليات المصرفية وتمويل
							المشاريع وإصدار المستندات المالية
						</p>
					</div>

					<div className="bg-white p-6 rounded-lg w-80 h-80 flex flex-col items-start">
						<img
							src="/assets/Vector-3.png"
							alt="Companies"
							className="w-12 h-12 object-cover rounded-lg mb-4 "
						/>
						<h3 className="text-2xl text-blue-900 font-semibold mb-4">
							الشركات والأنشطة التجارية
						</h3>
						<p className="text-gray-600 leading-relaxed">
							نقدم خدمات تأسيس الشركات وهيكلة الشركات بالإضافة إلى دعم الامتثال
							للأنظمة التجارية والاستثمار الأجنبي
						</p>
					</div>

					<div className="bg-white p-6 rounded-lg w-80 h-80 flex flex-col items-start">
						<img
							src="/assets/Vector-4.png"
							alt="Employees"
							className="rounded-lg mb-4"
						/>
						<h3 className="text-2xl text-blue-900 font-semibold mb-4">
							العمل والموظفين
						</h3>
						<p className="text-gray-600 leading-relaxed">
							نقدم خدمات قانونية متعلقة بعقود العمل وقوانين العمل الداخلية، بما
							في ذلك صياغة السياسات الداخلية للشركات
						</p>
					</div>

					<div className="bg-white p-6 rounded-lg w-80 h-80 flex flex-col items-start">
						<img
							src="/assets/Vector-5.png"
							alt="Litigation"
							className="rounded-lg mb-4"
						/>
						<h3 className="text-2xl text-blue-900 font-semibold mb-4">
							التقاضي
						</h3>
						<p className="text-gray-600 leading-relaxed">
							نمثل عملائنا في جميع أنواع المحاكم بما في ذلك المحاكم التجارية،
							والجنائية، والإدارية
						</p>
					</div>

					<div className="bg-white p-6 rounded-lg w-80 h-80 flex flex-col items-start">
						<img
							src="/assets/Vector-6.png"
							alt="Real Estate"
							className="rounded-lg mb-4"
						/>
						<h3 className="text-2xl text-blue-900 font-semibold mb-4">
							العقارات والتمويل العقاري
						</h3>
						<p className="text-gray-600 leading-relaxed">
							نقدم خدمات قانونية عميقة في قوانين العقارات والتمويل العقاري، بما
							في ذلك العقود والتمويل العقاري
						</p>
					</div>

					<div className="bg-white p-6 rounded-lg w-80 h-80 flex flex-col items-start">
						<img
							src="/assets/Vector-7.png"
							alt="Intellectual Property"
							className="rounded-lg mb-4"
						/>
						<h3 className="text-2xl text-blue-900 font-semibold mb-4">
							الملكية الفكرية
						</h3>
						<p className="text-gray-600 leading-relaxed">
							من بين خدماتنا تقديم الاستشارات المتعلقة بقوانين الملكية الفكرية
							وحمايتها
						</p>
					</div>

					<div className="bg-white p-6 rounded-lg w-80 h-80 flex flex-col items-start">
						<img
							src="/assets/Vector-8.png"
							alt="Stocks"
							className="rounded-lg mb-4"
						/>
						<h3 className="text-2xl text-blue-900 font-semibold mb-4">
							الشركات والأسهم وأسماء الأسهم
						</h3>
						<p className="text-gray-600 leading-relaxed">
							نقدم خدمات تأسيس الشركات وإدراج الأسهم في البورصة مع تقديم
							استشارات قانونية
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PracticeAreas;
