import React from "react";
import Aos from "aos";
import { useEffect } from "react";

import "aos/dist/aos.css";
const Services = () => {
	useEffect(() => {
		Aos.init({ duration: 1000, delay: 500 });
	});
	return (
		<div className="relative overflow-x-hidden z-0 h-full flex items-center justify-center overflow-hidden">
			<div
				className="absolute z-0 inset-0 bg-cover bg-center"
				style={{
					backgroundImage: `url(${"assets/Services.png"})`,
					opacity: 0.1,
					backgroundPosition: "center",
					backgroundSize: "50%",
				}}></div>

			<div className=" z-0 relative max-w-7xl mx-auto mt-[120px] px-12 py-8">
				<div data-aos="fade-right" className="text-left mb-12">
					<h1 className=" text-6xl font-bold text-black mb-4">خدماتنا</h1>

					<p
						className="text-lg font-semibold text-gray-600"
						style={{ direction: "ltr", unicodeBidi: "plaintext" }}>
						خدمات قانونية متخصصة في جميع القطاعات، تحمي مصالحك بدقة واحترافية.
					</p>
				</div>
				<div
					data-aos="fade-left"
					className="grid grid-cols-1 md:grid-cols-3 gap-8 text-left">
					<div className="bg-transparent p-6">
						<img
							src="assets/Vector.png"
							alt="Arbitration Icon"
							className="mb-4 "
						/>
						<h3 className="text-2xl font-semibold text-black mb-2">التحكيم</h3>

						<p
							className="text-gray-600 text-lg font-normal"
							style={{ direction: "ltr", unicodeBidi: "plaintext" }}>
							يعمل التحكيم على حل النزاعات المحلية والدولية بسرعة من خلال
							إجراءات واضحة. يتمتع فريق الخبراء لدينا بالمهارة في العمل كمحكمين
							أو تمثيل العملاء أمام هيئات التحكيم.
						</p>
					</div>

					<div className="bg-transparent p-6">
						<img
							src="assets/Vector-1.png"
							alt="Insurance Icon"
							className="mb-4 "
						/>
						<h3 className="text-2xl font-semibold text-black mb-2">تأمين</h3>
						<p
							className="text-gray-600 text-lg"
							style={{ direction: "ltr", unicodeBidi: "plaintext" }}>
							تعتبر قضايا التأمين من القضايا واسعة الانتشار التي تهم الأفراد
							والشركات، ولذلك فقد أولى فريقنا اهتماماً خاصاً بتقديم خدمات
							قانونية متنوعة ومتخصصة فيما يتعلق بقضايا التأمين.
						</p>
					</div>

					<div className="bg-transparent p-6">
						<img
							src="assets/Vector-2.png"
							alt="Banking Icon"
							className="mb-4 "
						/>
						<h3 className="text-2xl font-semibold text-black mb-2">
							العمليات المصرفية
						</h3>
						<p
							className="text-gray-600 text-lg"
							style={{ direction: "ltr", unicodeBidi: "plaintext" }}>
							يقدم فريقنا الخدمات القانونية لأنشطة المصرفية، بما في ذلك تمويل
							المشاريع والتمويل الإسلامي وإعادة هيكلة القروض وعمليات الاستحواذ.
							نحن نساعد أيضاً في إصدار المستندات والمعاملات المالية.
						</p>
					</div>
				</div>
				<div data-aos="fade-up" className="flex justify-center mt-12">
					<button className="font-bold px-12 py-3 text-white text-lg bg-[#001C38] hover:bg-white hover:text-black border-2 border-black transition-all duration-300">
						<a href="/consultation">شاهد جميع الخدمات</a>
					</button>
				</div>
			</div>
		</div>
	);
};

export default Services;
